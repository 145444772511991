import React, { useState, Fragment } from 'react';
import { Upload, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DragableUploadListItem } from 'src/modules/products/components/DragableUploadListItem';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const ImageGallery = ({ fileList, detailFileList, onGalleryUpdate, onGalleryOrderUpdate, beforeUpload, customRequest }) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const { t } = useTranslation();
  const text = textFrom('components.imageGallery', t);

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview || file.imageURL || file.imgUrl);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{text('upload')}</div>
    </div>
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <h5>{text('cover')}</h5>
      <Upload
        maxCount={1}
        accept='image/*'
        listType='picture-card'
        onChange={({ file, fileList }) => onGalleryUpdate(file, fileList, 'cover')}
        fileList={fileList}
        beforeUpload={(file) => {
          if (beforeUpload) {
            beforeUpload(file);
          } else {
            return false;
          }
        }}
        onPreview={handlePreview}
        customRequest={customRequest}
        showUploadList={{ showRemoveIcon: fileList.length > 1 }}
        itemRender={(originNode, file, currFileList) => (
          <Fragment key={file.uid}>
            <DragableUploadListItem
              originNode={originNode}
              file={file}
              fileList={currFileList}
            />
          </Fragment>
        )}
      >
        {fileList?.length < 100 && uploadButton}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt='example' style={{ width: '100%' }} src={previewImage} />
      </Modal>
      <h5>{text('detail')}</h5>
      <Upload
        accept='image/*'
        listType='picture-card'
        onChange={({ file, fileList, type }) => onGalleryUpdate(file, fileList, 'detail')}
        fileList={detailFileList}
        beforeUpload={() => {
          return false;
        }}
        onPreview={handlePreview}
        itemRender={(originNode, file, currFileList) => {
          return (
            <Fragment key={file.uid}>
              <DragableUploadListItem
                originNode={originNode}
                file={file}
                fileList={currFileList}
              />
            </Fragment>
          );
        }}
      >
        {fileList?.length < 100 && uploadButton}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt='example' className='w-full' src={previewImage} />
      </Modal>
    </DndProvider>
  );
};
