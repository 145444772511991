import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext
} from 'react';
import { message } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { useFilters } from 'src/hooks/useFilters';
import { OutfitsAPI } from '../api/OutfitsAPI';
import { useLocation, useParams } from 'react-router-dom';
import { usePagination } from 'src/hooks/usePagination';
import { OutfitsMapper } from '../domain/Outfits.mapper';
import { useTranslation } from 'react-i18next';
import { ProductsAPI } from 'src/modules/products/api/ProductsAPI';
import { VariantModel } from 'src/modules/products/domain/models/VariantModel';
import { Routes } from 'src/router/Routes.helper';
import { hasMoreThanXCharacters } from 'src/utils/utils';

const OutfitsContext = createContext();

const useProvideOutfits = (text) => {
  const location = useLocation();
  const [outfits, setOutfits] = useState([]);
  const [outfit, setOutfit] = useState();
  const [previousSearch, setPreviousSearch] = useState(undefined);
  const [selectedProduct, setSelectedProduct] = useState();
  const [productVariants, setProductVariants] = useState([]);
  const [outfitVariants, setOutfitVariants] = useState([]);
  const [pagination, setPagination] = usePagination();
  const { query, onChangeQuery } = useFilters(OutfitsMapper);
  const [areOutfitsLoading, setAreOutfitsLoading] = useState(false);
  const params = useParams();

  const getProductVariants = async () => {
    try {
      const res = await ProductsAPI.getProductVariants(selectedProduct);
      if (res.data.data) {
        const variants = res.data.data.map(
          (apiVariant) => new VariantModel(apiVariant)
        );
        setProductVariants(variants);
        return Promise.resolve();
      }
    } catch (e) {
      // @todo handle notification
      message.error(text('productVariantKO'));
      return Promise.reject(e);
    }
  };

  const getOutfitDetail = useCallback(async (id) => {
    try {
      const res = await OutfitsAPI.getOutfitByID(id);
      if (res?.data) {
        setOutfit(res.data);
        const variants = res.data?.variantList?.map(
          (apiVariant) => new VariantModel(apiVariant)
        );
        setOutfitVariants(variants || []);
        return Promise.resolve();
      }
    } catch (e) {
      // @todo handle notification
      message.error('Something went wrong!');
      return Promise.reject(e);
    }
  }, [params.id]);

  useEffect(() => {
    if (selectedProduct) {
      getProductVariants();
    }
  }, [selectedProduct]);

  const createOutfit = async (data) => {
    try {
      const res = await OutfitsAPI.createOutfit(data);
      message.success(text('onCreateOK'));
      return Promise.resolve(res);
    } catch (e) {
      message.error(text('onCreateKO'));
      return Promise.reject(e);
    } finally {
      setAreOutfitsLoading(false);
    }
  };

  const editOutfit = async (data) => {
    try {
      await OutfitsAPI.editOutfit(params.id, data);
      message.success(text('onEditOK'));
      return Promise.resolve();
    } catch (e) {
      message.error(text('onEditKO'));
      return Promise.reject(e);
    } finally {
      setAreOutfitsLoading(false);
    }
  };

  const getOutfits = useCallback(async (query) => {
    setAreOutfitsLoading(true);
    const outfitQuery = { ...query };
    try {
      const res = await OutfitsAPI.getOutfits(OutfitsMapper.fromQueryToPayload(outfitQuery));
      setOutfits(res?.data?.items || []);
      setPagination(res?.data || {});
      return Promise.resolve();
    } catch (e) {
      message.error(text('failedToRetrieveOutfits'));
      return Promise.reject(e);
    } finally {
      setAreOutfitsLoading(false);
    }
  }, [query, setPagination, location]);

  useEffect(() => {
    if (previousSearch === undefined || hasMoreThanXCharacters(query.text, 2) || hasMoreThanXCharacters(previousSearch, 2)) {
      getOutfits(query);
    }
    setPreviousSearch(query.text);
  }, [location.search, query]);

  useEffect(() => {
    if (params.id) {
      const acceptedLocationPath = Routes.parseRouteParams(Routes.PATHS.OUTFIT_EDIT, {
        id: params.id
      });
      const isEditOutfitFlow = location.pathname.includes(acceptedLocationPath);
      if (isEditOutfitFlow) {
        getOutfitDetail(params.id);
      }
    }
  }, [params.id, location.pathname]);

  return {
    query,
    outfits,
    pagination,
    setOutfits,
    onChangeQuery,
    areOutfitsLoading,
    outfitVariants,
    setOutfitVariants,
    getProductVariants,
    selectedProduct,
    setSelectedProduct,
    productVariants,
    setProductVariants,
    outfit,
    createOutfit,
    editOutfit,
    getOutfitDetail,
    refreshOutfits: getOutfits
  };
};

export const OutfitsProvider = ({ children }) => {
  const { t } = useTranslation();
  const text = textFrom('providers.outfits', t);

  const outfits = useProvideOutfits(text);

  return (
    <OutfitsContext.Provider value={outfits}>
      {children}
    </OutfitsContext.Provider>
  );
};

export const useOutfits = () => useContext(OutfitsContext);
