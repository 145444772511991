import React from 'react';
import { Row, Col, Typography } from 'antd';
import { ImageUpload } from './ImageUpload';
import { useAccounts } from '../provider/accounts.provider';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { imageType } from 'src/utils/utils';

const { Title } = Typography;

export const Images = () => {
  const { t } = useTranslation();
  const text = textFrom('components.images', t);

  const { userInfo } = useAccounts();

  return (
    <>
      <Row>
        <Col xs={18} className='border-b border-gray-300 py-6'>
          <Title level={5}>{text('images')}</Title>
          <div className='mt-8'>
            <div className='mb-4'>
              <h5>{text('logo')}<span style={{ color: '#777', fontSize: 12, fontWeight: 400 }}> - {text('description')}</span></h5>
              <ImageUpload
                type={imageType.logo}
                userInfo={userInfo}
              />
            </div>
            <div className='mb-4'>
              <h5>{text('map')}<span style={{ color: '#777', fontSize: 12, fontWeight: 400 }}> - {text('description')}</span></h5>
              <ImageUpload
                type={imageType.map}
                userInfo={userInfo}
              />
            </div>
            <div className='mb-4'>
              <h5>{text('cover')}<span style={{ color: '#777', fontSize: 12, fontWeight: 400 }}> - {text('description')}</span></h5>
              <ImageUpload
                hideType
                type={imageType.cover}
                description=''
                userInfo={userInfo}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
