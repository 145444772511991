const PERMITTED_ROLES = [
  'ROLE_CLIENTELING_SALES_ASSISTANT',
  'ROLE_LOCAL_BUSINESS_MANAGER'
];

export function isRolePermitted (role) {
  return PERMITTED_ROLES.includes(role.roleName);
}

export function toRoleOption (role) {
  return {
    value: role.roleName,
    label: role.label
  };
}

export const Gender = Object.freeze({
  MALE: 'male',
  FEMALE: 'female',
  NA: 'n/a'
});

export const LOCALBUSINESS_FORM = {
  KEY: 'key',
  LABEL: 'label',
  CHECKED: 'checked',
  MICRO_TAGS: 'microTags',
  MACRO_TAGS: 'macroTags',
  GENERAL_TAGS: 'generalTags'
};

export const getCheckedTags =
  (form, keyField, checkedField) =>
    tagsField => (
      form
        .getFieldValue(tagsField)
        ?.filter(tag => tag[checkedField])
        .map(tag => ({ key: tag[keyField] })) ||
        []
    );
