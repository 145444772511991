import React, { useState, useEffect } from 'react';
import { Upload, message, Modal } from 'antd';
import {
  LoadingOutlined,
  PlusOutlined,
  EyeOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { AccountsAPI } from '../api/AccountsApi';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { imageType } from 'src/utils/utils';

function getBase64 (img, callback) {
  const reader = new window.FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export const ImageUpload = ({ type, userInfo }) => {
  const { t } = useTranslation();
  const text = textFrom('components.imageUpload', t);

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [isDeleteBtnVisible, setIsDeleteBtnVisible] = useState(false);
  const [isPreviewBtnVisible, setIsPreviewBtnVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const [newImage, setNewImage] = useState(null);
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    const hasImage =
      userInfo &&
      userInfo.localBusiness.gallery &&
      userInfo.localBusiness.gallery.length > 0;
    const hasLogo =
      userInfo &&
      userInfo.localBusiness.logo &&
      userInfo.localBusiness.logo.imageURL;

    if (type === imageType.logo && hasLogo) {
      setImageUrl(hasLogo);
    } else {
      if (hasImage) {
        const image = userInfo.localBusiness.gallery.find(
          (item) => item.type === type
          // (item) => item.type === 'local-business-photo' && item.sizeType === type.toUpperCase()
        );
        if (image) {
          setImageUrl(image.imageURL);
        }
      }
    }
  }, [userInfo]);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleShowPreview = () => {
    setPreviewImage(true);
  };

  const handleCancelPreview = () => {
    setPreviewImage(false);
  };

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (imageUrl) => {
        console.log('imageUrl', imageUrl);
        setNewImage(imageUrl);
        setLoading(false);
      });
    }
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    setFileList(fileList);
  };

  const handleUpload = async (options) => {
    const { onSuccess, onError, file } = options;

    try {
      if (type === imageType.logo) {
        const fileUpload = await AccountsAPI.uploadLogo(userInfo.localBusiness.id, file);
        setLoading(false);
        setImageUrl(fileUpload.data.data.logo.imageURL);
        message.success(text('onUploadOk'));
        onSuccess('Ok');
      } else {
        await AccountsAPI.deleteSpecificImage(userInfo.localBusiness.id, type.toUpperCase());
        await AccountsAPI.uploadImage(userInfo.localBusiness.id, file, type.toUpperCase());
        const me = await AccountsAPI.getUserInfo();
        const image = me.data.data.localBusiness.gallery.find(
          (item) => item.type === type
          // (item) => item.type === 'local-business-photo' && item.sizeType === type.toUpperCase()
        );
        if (image) {
          setImageUrl(image.imageURL);
        }
        setLoading(false);
        message.success(text('onUploadOk'));
        onSuccess('Ok');
      }
    } catch (error) {
      setLoading(false);
      message.error(text('onUploadErr'));
      onError({ error });
    }
  };

  const handleRemove = async (e) => {
    e.preventDefault();
    try {
      await AccountsAPI.deleteImage(userInfo.localBusiness.id, type);
      setImageUrl(null);
      message.success(text('onDeleteOk'));
    } catch (error) {
      message.error(text('onDeleteErr'));
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPngOrTiff =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/tiff';
    if (!isJpgOrPngOrTiff) {
      message.error(text('onImageWrongFormat'));
    }
    const isLt6M = file.size / 1024 / 1024 < 6;
    if (!isLt6M) {
      message.error(text('onImageTooBig'));
    }
    return isJpgOrPngOrTiff && isLt6M;
  };

  return (
    <div className='flex items-center w-full'>
      {!newImage && (
        <CustomImagePreview
          imageUrl={imageUrl}
          isDeleteBtnVisible={isDeleteBtnVisible}
          setIsDeleteBtnVisible={setIsDeleteBtnVisible}
          handleRemove={handleRemove}
          isPreviewBtnVisible={isPreviewBtnVisible}
          setIsPreviewBtnVisible={setIsPreviewBtnVisible}
          setPreviewImage={setPreviewImage}
        />
      )}
      <Upload
        fileList={fileList}
        name='avatar'
        listType='picture-card'
        className='avatar-uploader'
        customRequest={handleUpload}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        // onChange={(file, fileList, event) => onGalleryUpdate(file, fileList, event)}
        onRemove={handleRemove}
        openFileDialogOnClick
      >
        {uploadButton}
      </Upload>
      {previewImage && (
        <Modal
          visible={handleShowPreview}
          footer={null}
          onCancel={handleCancelPreview}
        >
          <img alt='example' style={{ width: '100%' }} src={imageUrl} />
        </Modal>
      )}
    </div>
  );
};

const CustomImagePreview = ({
  imageUrl,
  isDeleteBtnVisible,
  setIsDeleteBtnVisible,
  handleRemove,
  setIsPreviewBtnVisible,
  isPreviewBtnVisible,
  setPreviewImage
}) => {
  const onHoverHandler = (value) => {
    setIsDeleteBtnVisible(value);
    setIsPreviewBtnVisible(value);
  };

  const handlePreview = () => {
    setPreviewImage(true);
  };

  return (
    imageUrl && (
      <div
        className='relative ant-upload-list-picture-card-container'
        onMouseEnter={() => onHoverHandler(true)}
        onMouseLeave={() => onHoverHandler(false)}
      >
        <img className='customImagePreview' src={imageUrl} alt='' />
        <div className={`hoverHandler ${isDeleteBtnVisible ? 'hoverIt' : ''}`} />
        {isPreviewBtnVisible && (
          <DeleteOutlined
            className='absolute top-8 left-11 cursor-pointer z-10'
            style={{ color: 'white' }}
            onClick={handleRemove}
          />
        )}
        {isDeleteBtnVisible && (
          <EyeOutlined
            className='absolute top-14 left-11 cursor-pointer z-10'
            style={{ color: 'white' }}
            onClick={handlePreview}
          />
        )}
      </div>
    )
  );
};
