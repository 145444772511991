import React, { useCallback, useMemo, useState } from 'react';
import { Row, Col, Button, Select, message } from 'antd';
import { PageTitle } from 'src/components/PageTitle';
import { SearchInput } from 'src/components/inputs/SearchInput';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { useUsers } from 'src/modules/admin/provider/users.provider';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { Routes } from 'src/router/Routes.helper';
import { Chips } from 'src/components/Chips';
import { ConfirmationModal } from 'src/components/modals/ConfirmationModal';
import { CenteredText } from 'src/components/CenteredText';
import { UserTable } from '../component/UserTable';
import { toRoleOption } from '../utils/utils';
import { optionMatchesInput } from 'src/utils/utils';

export const PageUsers = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.users', t);
  const textFilter = textFrom('constants.filter', t);

  const { signOut, loginAs, canManageUsers } = useAuth();
  const {
    query,
    users,
    roles,
    pagination,
    onChangeQuery,
    areUsersLoading,
    requestVerificationEmail
  } = useUsers();

  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [userToLogAsEmail, setUserToLogAsEmail] = useState('');

  const FILTERS = {
    USER: 'user',
    GROUP: 'group'
  };
  const { USER, GROUP } = FILTERS;

  const handleSearchUser = (searchedText) => {
    onChangeQuery({
      ...query,
      user: searchedText
    });
  };

  const handleSelectGroup = (selectedGroup) => {
    onChangeQuery({
      ...query,
      group: selectedGroup
    });
  };

  const onStartEditing = (record) => {
    history.push(Routes.parseRouteParams(Routes.PATHS.USERS_EDIT, {
      userId: record.id
    }));
  };

  const onTableChange = ({ current, pageSize }) => {
    onChangeQuery({ current, pageSize });
  };

  const handleDelete = () => { };

  const handleLogInAs = (user) => {
    if (user?.email && user?.active) {
      const newModalTitle = text('modal.title', { account: user.username || user.email });
      setModalTitle(newModalTitle);
      const newModalBody = text('modal.logInto', { account: user.username || user.email });
      setModalBody(newModalBody);
      setUserToLogAsEmail(user.email);
      showModal();
    } else {
      if (!user) {
        message.error(text('error.noUser'));
      } else if (!user.active) {
        message.error(text('error.userNotActive'));
      } else if (!user.email) {
        message.error(text('error.missingUserEmail'));
      }
    }
  };

  const ROLE_OPTIONS = useMemo(() => (
    roles.map(toRoleOption)
  ), [roles]);

  const showModal = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const handleModalOk = useCallback(async () => {
    setIsModalVisible(false);
    signOut();
    await loginAs(userToLogAsEmail);
    return history.push(Routes.PATHS.DASHBOARD);
  }, [userToLogAsEmail]);

  const GroupSelect = () => {
    return (
      <Select
        bordered
        showSearch
        allowClear
        className='w-full'
        value={query.group}
        onChange={handleSelectGroup}
        options={ROLE_OPTIONS}
        filterOption={optionMatchesInput}
        placeholder={text('selectPlaceholder')}
        getPopupContainer={trigger => trigger.parentNode}
      />
    );
  };

  const handleModalCancel = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const UserSearch = () => {
    return (
      <SearchInput
        autoFocus
        id='userSearch'
        defaultValue={query.user}
        onChangeText={handleSearchUser}
        placeholder={text('searchPlaceholder')}
      />
    );
  };

  const CreateUsersButton = () => {
    return (
      <Button
        className='uppercase'
        type='primary'
        onClick={goToCreateUserPage}
      >
        {text('createNewUser')}
      </Button>
    );
  };

  const goToCreateUserPage = () => {
    history.push(Routes.parseRouteParams(Routes.PATHS.USERS_CREATE));
  };

  const onTagRemove = (key) => {
    if (key === USER) {
      handleSearchUser('');
    } else if (key === GROUP) {
      handleSelectGroup(null);
    }
  };

  return (
    <div>
      <ConfirmationModal
        isVisible={isModalVisible}
        title={modalTitle}
        body={modalBody}
        onConfirm={handleModalOk}
        onDismiss={handleModalCancel}
      />
      <div className='py-4 bg-gray-100 page-filters'>
        <PageTitle>{text('users')}</PageTitle>
        <Row gutter={[16, 16]} className='pt-2'>

          <Col span={6}>
            <UserSearch />
          </Col>

          {canManageUsers &&
            <Col span={6}>
              <GroupSelect />
            </Col>}

          <Col className='last-column'>
            <CreateUsersButton />
          </Col>
        </Row>
        {/* Filter Chips */}
        {(!!query?.user || !!query?.group) &&
          <Row className='py-4'>
            <div className='pr-2'>{textFilter('appliedFilter', { count: +(!!query.user && +1) + (!!query.group && +1) })}:
              <Chips
                query={query}
                chipKeys={[USER, GROUP]}
                onTagRemove={onTagRemove}
              />
            </div>
          </Row>}
      </div>
      {!areUsersLoading && !users?.length
        ? (<CenteredText>{text('noUsersFound')}</CenteredText>)
        : (<UserTable
            users={users}
            loading={areUsersLoading}
            onEdit={onStartEditing}
            onRequestVerificationEmail={requestVerificationEmail}
            onDelete={handleDelete}
            onLoginAs={handleLogInAs}
            pagination={pagination}
            onTableChange={onTableChange}
           />)}
    </div>
  );
};
