export const SEPARATOR = '%%%';
export const KEY_REGEX = /^[a-zA-Z0-9_-]*$/;

export const WEBHOOK_CONTEXTS = {
  paymentError: 'cart.payment-error',
  statusTransition: 'order.status-transition',
  BOUserCreation: 'backoffice-user.creation',
  CustomerCreation: 'customer-user.creation'
};

export const BACKOFFICE_TIMEZONES = [{ label: 'UTC', value: 'utc' }];
