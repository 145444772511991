import { Product } from 'src/services/Product.service';

export class VariantUpdatePayload {
  constructor (variant, formData) {
    this.manufacturerCode = formData.manufacturerCode;
    this.attributeVariants = {
      size: formData.size?.key,
      sizeScale: formData.sizeScale?.key
    };
    this.prices = {
      sellingPrice: parseFloat(formData.price)
    };
    this.published = Product.getStatusBooleanValue(variant.published);

    if (formData.color) {
      this.attributeVariants.color = {
        colorSearch: formData.color?.key,
        hex: null,
        id: formData.color?.id,
        key: formData.color?.key,
        localeName: formData.color?.label
      };
    }
  }
}
