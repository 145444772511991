import React, {
  useRef,
  useState,
  useEffect
} from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button } from 'antd';
import { useCoupons } from 'src/modules/coupons/provider/coupons.provider';
import { DrawerCouponSideDetails } from 'src/modules/coupons/components/DrawerCouponSideDetails';
import { SearchInput } from 'src/components/inputs/SearchInput';
import { PageTitle } from 'src/components/PageTitle';
import { CouponsAPI } from 'src/modules/coupons/api/CouponsAPI';
import { message } from 'src/services/Messages.service';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { DeletionModal } from 'src/components/DeletionModal';
import { Chips } from 'src/components/Chips';
import { CenteredText } from 'src/components/CenteredText';
import { CouponTable } from '../components/CouponTable';
import moment from 'moment';
import { SCOPE } from 'src/utils/scope-utils';
import { LocalBusinessesQuery } from 'src/modules/admin/domain/models/LocalBusinessesQuery';
import { SelectLocalBusinessWithLoadMore } from 'src/components/SelectLocalBusinessWithLoadMore';

const { KEYS } = LocalBusinessesQuery;

const formatDate = (date) => {
  const sourceFormat = 'YYYY-MM-DD HH-mm-ss';
  return moment.utc(date, sourceFormat).valueOf();
};

export const PageCoupons = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.coupons', t);
  const textFilter = textFrom('constants.filter', t);

  const PERCENTAGE_DISCOUNT = 'PERCENTAGE_DISCOUNT';
  const NET_VALUE_DISCOUNT = 'NET_VALUE_DISCOUNT';
  const FIXED = text('fixed');
  const PERCENTAGE = text('percentage');
  const header = useRef();
  const history = useHistory();
  const { canManageCoupons, selectedUserScope } = useAuth();

  const {
    query,
    coupons,
    pagination,
    onChangeQuery,
    areCouponsLoading,
    refreshData
  } = useCoupons();

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [couponToDelete, setCouponToDelete] = useState(null);
  const [selectedLocalBusiness, setSelectedLocalBusiness] = useState();

  /**
   * @description This method handle the table data in case user changes the page or change the total items displayed on that page
   * @param {{
   *  current: string
   *  pageSize: number
   * }} config
   * @returns {string}
   */
  const onTableChange = ({ current, pageSize }) => {
    onChangeQuery({ current, pageSize });
  };

  /**
   * @description This method handle the click on save button inside the drawer.
   * @param closeDrawer
   */
  const onSaveDetails = (activationStatus, closeDrawer = false) => {
    selectedCoupon.activationStatus = activationStatus;
    setSelectedCoupon({ ...selectedCoupon });
    handleUpdate(selectedCoupon);
    closeDrawer && onCloseDetails();
  };

  /**
   * @description This method handle the closing of the drawer.
   */
  const onCloseDetails = () => {
    setDrawerVisible(false);
  };

  /**
   * @description This method handle the opening of the drawer.
   * @param coupon
   */
  const onOpenDetails = (coupon) => {
    setSelectedCoupon(coupon);
    setDrawerVisible(true);
  };

  /**
   * @description This method handles update coupon - Inline update - check table actions
   * @param record
   * @returns {Promise<void>}
   */
  const handleUpdate = async (record) => {
    const cartRule = (record.cartRules?.length) ? record.cartRules[0] : null;
    const action = (cartRule?.actions?.length) ? cartRule?.actions[0] : null;
    const isPercentage = action?.text === PERCENTAGE_DISCOUNT;
    const isNetValue = action?.text === NET_VALUE_DISCOUNT;
    try {
      const couponUpdateData = {
        activationStatus: record.activationStatus,
        applicationCode: record.applicationCode,
        description: cartRule?.description,
        discountAbsolute: isNetValue ? action?.value : null,
        discountPercentage: isPercentage ? action?.value : null,
        startDate: formatDate(record?.startDate),
        endDate: formatDate(record?.endDate),
        localBusinessId: record.localBusiness?.id,
        maxUtilization: record.maxUtilizationNumber,
        maxUtilizationPerUser: record.maxUtilizationNumberPerUser
      };

      const LOCAL_BUSINESS_IN_CART_KEY = 'LOCAL_BUSINESS_IN_CART';
      const localBusinessInCartConditionValue = record?.cartRules?.flatMap(rule => rule.conditions).find(condition => condition.text === LOCAL_BUSINESS_IN_CART_KEY)?.value;
      if (localBusinessInCartConditionValue) {
        const localBusinessIds = [localBusinessInCartConditionValue];
        couponUpdateData.localBusinessIds = localBusinessIds;
      }

      await CouponsAPI.updateCoupon(record.id, couponUpdateData);
      message.success(text('onUpdateOk'));
      refreshData(query);
    } catch (e) {
      message.error(e);
    }
  };

  /**
   * @description This method handles delete coupon - Inline delete - check table actions
   * @param record
   * @returns {Promise<void>}
   */
  const handleDelete = async (record) => {
    try {
      await CouponsAPI.deleteCoupon(record.id);
      message.success(text('onDeleteOk'));
      refreshData();
    } catch (e) {
      message.error(e);
    }
  };

  /**
   * @description ApplicationCode/CouponCode search - for the moment handles only ean and it is not an autocomplete
   * @param value
   */
  const handleCouponCodeSearch = (value) => {
    onChangeQuery({
      ...query,
      applicationCode: value,
      current: 1
    });
  };

  const handleSelectLocalBusiness = (_, lbOption) => {
    setSelectedLocalBusiness(lbOption);
    onChangeQuery({
      ...query,
      localBusinessId: lbOption?.id,
      localBusinessName: lbOption?.label,
      current: 1
    });
  };

  const typeExtractor = (record) => {
    let type = null;
    if (record?.cartRules?.length && record?.cartRules[0]?.actions.length) {
      type = record.cartRules[0].actions.find((action) =>
        (action.text === PERCENTAGE_DISCOUNT || action.text === NET_VALUE_DISCOUNT)
      );
    }
    const typeInfo = {
      label: (type) ? (type.text === PERCENTAGE_DISCOUNT) ? PERCENTAGE : FIXED : '',
      symbol: (type) ? (type.text === PERCENTAGE_DISCOUNT) ? '%' : '€' : '',
      value: (type?.value) ? type.value : 0
    };
    return typeInfo;
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
    handleDelete(couponToDelete);
  };

  useEffect(() => {
    if (!selectedLocalBusiness && query.localBusinessId && query.localBusinessName) {
      handleSelectLocalBusiness(query.localBusinessId, {
        id: query.localBusinessId,
        key: query.localBusinessId,
        value: query.localBusinessId,
        label: query.localBusinessName
      });
    }
  }, [query.localBusinessId, query.localBusinessName, selectedLocalBusiness]);

  const onTagRemove = (key) => {
    if (key === KEYS.APPLICATION_CODE) {
      handleCouponCodeSearch('');
    } else if (key === KEYS.LOCAL_BUSINESS_ID) {
      handleSelectLocalBusiness(null);
    }
  };

  const clearLocalBusiness = () => handleSelectLocalBusiness(null);

  return (
    <>
      <div ref={header} className='py-4'>
        <DeletionModal
          onOk={handleModalOk}
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
        >
          <div style={{ 'white-space': 'pre-line' }}>{`${text('deletionConsequence')}\n\n${text('askConfirmation')}`}</div>
        </DeletionModal>

        <PageTitle>{text('title')}</PageTitle>

        <Row gutter={[16, 16]} className='pt-2'>
          <Col span={6}>
            <SearchInput
              autoFocus
              placeholder={text('searchPlaceholder')}
              onChangeText={handleCouponCodeSearch}
              defaultValue={query.applicationCode}
            />
          </Col>

          <Col span={6}>
            {canManageCoupons &&
              <SelectLocalBusinessWithLoadMore
                value={selectedLocalBusiness?.label}
                onSelect={handleSelectLocalBusiness}
                onClear={clearLocalBusiness}
                placeholder={text('selectPlaceholder')}
              />}
          </Col>

          <Col span={6} />

          {canManageCoupons &&
            <Col span={6}>
              <div className='flex justify-end space-x-6'>
                <Button
                  type='primary'
                  className='uppercase'
                  onClick={() => history.push('/app/coupon/create')}
                >
                  {text('createNewCoupon')}
                </Button>
              </div>
            </Col>}
        </Row>

        {/* Filter Chips */}
        {(!!query?.applicationCode || (!!query?.localBusinessId && selectedUserScope !== SCOPE.LOCALBUSINESS)) &&
          <Row className='py-4'>
            <div className='pr-2'>{textFilter('appliedFilter', { count: +(!!query.applicationCode && +1) + (!!query.localBusinessId && +1) })}:
              <Chips
                query={query}
                chipKeys={[KEYS.APPLICATION_CODE, KEYS.LOCAL_BUSINESS_ID]}
                specialChips={[{ key: KEYS.LOCAL_BUSINESS_ID, value: selectedLocalBusiness?.label }]}
                onTagRemove={onTagRemove}
              />
            </div>
          </Row>}
      </div>

      {!areCouponsLoading && !coupons?.length
        ? (<CenteredText>{text('noCouponsFound')}</CenteredText>)
        : (<CouponTable
            loading={areCouponsLoading}
            coupons={coupons}
            typeExtractor={typeExtractor}
            setCouponToDelete={setCouponToDelete}
            showModal={showModal}
            pagination={pagination}
            onTableChange={onTableChange}
            onRow={(record) => {
              return {
                onClick: () => {
                  onOpenDetails(record);
                }
              };
            }}
           />)}

      <DrawerCouponSideDetails
        selectedCoupon={selectedCoupon}
        visible={drawerVisible}
        typeExtractor={typeExtractor}
        onClose={onCloseDetails}
        onApply={onSaveDetails}
      />
    </>
  );
};
