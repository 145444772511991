import React, { useState, useEffect, useMemo } from 'react';
import { BackButton } from 'src/components/BackButton';
import { Row, Col, Button, Form, Checkbox, Typography, Input, message, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { useForm } from 'antd/lib/form/Form';
import { WebhookAPI } from '../api/webhookAPI';
import { useWebhooks } from '../provider/webhook.provider';
import { Routes } from 'src/router/Routes.helper';
import { PageTitle } from 'src/components/PageTitle';
import { WEBHOOK_CONTEXTS } from 'src/utils/constants';

const { Text } = Typography;
const { Option } = Select;

export const PageEditWebhook = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.webhooks/edit', t);
  const history = useHistory();
  const { webhook } = useWebhooks();
  const [form] = useForm();

  const [contextsArray, setContextsArray] = useState([]);

  //  Since contentType and sslVerification input fields are disabled and
  //  their default value is locked...
  const defaultContentType = 'application/json';

  const CONTEXT_ARRAY = [
    WEBHOOK_CONTEXTS.paymentError,
    WEBHOOK_CONTEXTS.statusTransition,
    WEBHOOK_CONTEXTS.BOUserCreation,
    WEBHOOK_CONTEXTS.CustomerCreation
  ];

  const defaultSSLVerification = 'ON';
  const HTTPS = 'https://';
  const payloadURLParsingRule = /.*(:\/\/)/;

  useEffect(() => {
    if (webhook) {
      const {
        clientId,
        url,
        contexts
      } = webhook;
      form.setFieldsValue({
        payloadURL: url.replace(payloadURLParsingRule, ''),
        cliendID: clientId
      });
      const objectArrayWithValues = CONTEXT_ARRAY.map((itm) => {
        return {
          key: itm,
          isSelected: contexts.includes(itm)
        };
      });
      setContextsArray(objectArrayWithValues);
    }
  }, [webhook]);

  const onFinish = async (value) => {
    const {
      payloadURL
    } = value;
    const contexts = [];

    contextsArray.forEach(element => {
      if (element.isSelected) {
        contexts.push(element.key);
      }
    });
    const webhookPayload = {
      ...webhook,
      url: `${HTTPS}${payloadURL}`,
      contexts: contexts
    };
    try {
      await WebhookAPI.updateWebhook(webhookPayload);
      message.success(text('webhookUpdateOK'));
      navigateToPageWebhooks();
    } catch (e) {
      message.error(text('webhookUpdateKO'));
    }
  };

  const manageEvents = (itm) => {
    const copyOfContextsArray = [...contextsArray];
    const index = contextsArray.findIndex(object => {
      return object.key === itm.key;
    });
    copyOfContextsArray[index].isSelected = !itm.isSelected;
    setContextsArray(copyOfContextsArray);
  };

  const navigateToPageWebhooks = () => {
    history.push(Routes.parseRouteParams(Routes.PATHS.WEBHOOKS));
  };

  const onCancel = () => {
    navigateToPageWebhooks();
  };

  const selectPayloadUrlAddonBefore = useMemo(() => {
    return (
      <Select defaultValue={HTTPS}>
        <Option value={HTTPS}>{HTTPS}</Option>
      </Select>
    );
  }, []);

  const parsePayloadUrlProtocol = () => {
    const payloadURLFormValue = form.getFieldsValue().payloadURL;
    const parsedPayloadURL = payloadURLFormValue.replace(payloadURLParsingRule, '');
    form.setFieldsValue({
      payloadURL: parsedPayloadURL
    });
  };

  return (
    <>
      <div className='py-4'>
        <Form
          form={form}
          layout='vertical'
          autoComplete='off'
          initialValues={
            {
              payloadURL: null,
              cliendID: null,
              contentType: defaultContentType,
              sslVerification: defaultSSLVerification
            }
          }
          scrollToFirstError
          onFinish={onFinish}
        >
          <PageTitle>{text('editWebhookConfigurator')}</PageTitle>
          <BackButton
            iconChevron
            className='mt-4 mb-8'
            onClick={navigateToPageWebhooks}
            title={text('backButton')}
          />
          <Row gutter={20} className='pt-2 mb-4'>
            <Col span={8}>
              <Form.Item
                name='payloadURL'
                label={text('payloadURL')}
                className='w-full px-4 pb-4'
                rules={[{ required: true, message: text('payloadURLRequired') }]}
              >
                <Input
                  onBlur={parsePayloadUrlProtocol}
                  addonBefore={selectPayloadUrlAddonBefore}
                  placeholder={text('payloadURLPlaceholder')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20} className='mb-4'>
            <Col span={4}>
              <Form.Item
                name='cliendID'
                label={text('cliendID')}
                className='w-full px-4 pb-4'
                rules={[{ required: true, message: text('clientIDRequired') }]}
                disabled
              >
                <Input disabled placeholder={text('clientIDPlaceholder')} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name='contentType'
                label={text('contentType')}
                className='w-full px-4 pb-4'
              >
                <Input
                  placeholder={text('contentTypePlaceholder')}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name='sslVerification'
                label={text('sslVerification')}
                className='w-full px-4 pb-4'
              >
                <Input
                  placeholder={text('sslVerificationPlaceholder')}
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20} className='mb-4'>
            <Col>
              <Row className='mb-2'>
                <Text>{text('whichWebhookEventsTriggered')}</Text>
              </Row>
              {contextsArray.map((itm, index) => {
                return (
                  <Row className='mb-2' key={index}>
                    <Checkbox
                      name={itm}
                      checked={itm.isSelected}
                      onClick={() => manageEvents(itm)}
                    >
                      {text(itm.key)}
                    </Checkbox>
                  </Row>
                );
              })}
            </Col>
          </Row>
          <div className='flex items-center w-full'>
            <Button
              type='default'
              htmlType='button'
              className='uppercase mr-2 ml-auto'
              onClick={onCancel}
            >
              {text('cancel')}
            </Button>
            <Button
              type='primary'
              htmlType='submit'
              className='uppercase ml-2'
            >
              {text('editWebhook')}
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};
