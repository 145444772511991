import React, { useState } from 'react';
import { Button, Popover } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

export const PopoverActionMenu = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopover = () => setIsOpen(x => !x);

  const closePopover = () => setIsOpen(false);

  return (
    <Popover
      visible={isOpen}
      trigger='click'
      placement='bottom'
      onClick={togglePopover}
      content={
        <div
          className='popover-actions'
          onClick={closePopover}
        >
          {children}
        </div>
      }
    >
      <MenuOutlined
        className='text-xl editable-table-menu-icon'
      />
    </Popover>
  );
};

export const MenuAction = ({ onClick, label, disabled }) => (
  <Button
    disabled={disabled}
    type='link'
    size='small'
    onClick={onClick}
  >
    {label}
  </Button>
);
