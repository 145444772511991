import React, { useMemo, useRef, useState } from 'react';
import { Row, Col, Drawer, Table } from 'antd';
// import { useDownloadFile } from 'src/hooks/useDownloadFile';
import { SearchInput } from 'src/components/inputs/SearchInput';
// import { message } from 'src/services/Messages.service';
import moment from 'moment';
import { useOrders } from '../provider/ordersEvents.provider';
import { ORDER_STATUS } from 'src/constants/eventStatus';
import { OrderDetails } from '../components/OrderDetails';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { PageTitle } from 'src/components/PageTitle';
import { Chips } from 'src/components/Chips';

export const PagePastOrders = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.orders/past-events', t);
  const textFilter = textFrom('constants.filter', t);
  const { canSeeCustomerData } = useAuth();

  const FILTERS = {
    TEXT: 'text'
  };
  const { TEXT } = FILTERS;

  const header = useRef();
  /* const downloadFile = useDownloadFile({
    onError (err) {
      message.error(
        err.response?.data?.detail || err.response?.data || err.message
      );
    }
  }); */
  const {
    query,
    products: orders,
    pagination,
    onChangeQuery,
    // refreshData,
    orderType
  } = useOrders();

  // const searchParameters = ['current', 'pageSize'];
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  /**
   * @description  Handle all the columns being displayed on the order table
   * @type {[{dataIndex: string[], width: string, title: string, render: (function(*, *))}, {dataIndex: string[], editable: boolean, width: string, inputType: string, title: string, render: (function(*, *))}, {inputOptions, dataIndex: string[], editable: boolean, inputType: string, title: string, render: (function(*, *): *)}, {inputOptions, dataIndex: string[], editable: boolean, inputType: string, title: string, render: (function(*, *): *)}, {inputOptions, dataIndex: string[], inputType: string, title: string, render: (function(*))}, null, null]}
   */

  const sumTicketsPurchasedQuantity = (itm) => itm.productOrderList.reduce(
    (previousValue, currentValue) => previousValue + currentValue.purchasedQuantity,
    0
  );

  const columns = useMemo(
    () => [
      {
        title: text('table.cols.orderTime'),
        dataIndex: 'placeDate',
        key: 'placeDate',
        render: (date) => (
          <div className='flex items-center space-x-2'>
            {moment(date).format('HH:mm DD MMM YYYY')}
          </div>
        )
      },
      {
        title: text('table.cols.orderNumber'),
        dataIndex: 'orderIdentifier',
        key: 'orderIdentifier'
      },
      {
        title: text('table.cols.eventName'),
        dataIndex: ['id'],
        key: ['id'],
        render: (_, record) => {
          return (
            <span>
              {record.productOrderList[0].names.it.value}
            </span>
          );
        }
      },
      {
        title: text('table.cols.customer'),
        dataIndex: ['customer', 'givenName'],
        key: ['customer', 'givenName'],
        render: (_, record) => (
          <span>{`${record.customer.givenName} ${record.customer.familyName}`}</span>
        )
      },
      {
        title: text('table.cols.tickets'),
        dataIndex: ['id'],
        key: ['id'],
        render: (_, record) => (
          <span>{sumTicketsPurchasedQuantity(record)}</span>
        )
      },
      {
        title: text('table.cols.price'),
        dataIndex: ['totals', 'sellingPrice'],
        key: ['totals', 'sellingPrice'],
        render: (_, record) => <span>{`€ ${record.productOrderList[0].prices.sellingPrice}`}</span>
      },
      {
        title: text('table.cols.totalAmount'),
        dataIndex: ['totals', 'finalGrandTotal'],
        key: ['totals', 'finalGrandTotal'],
        render: (_, record) => (
          <span>{`€ ${record.totals.finalGrandTotal}`}</span>
        )
      },
      {
        title: text('table.cols.status'),
        dataIndex: 'status',
        key: 'status',
        render: (_, record) =>
          <span>{record.statusTranslated || ORDER_STATUS[record.status]}</span>
      }
    ].filter(col => (!col.dataIndex.includes('customer') || canSeeCustomerData)),
    []
  );

  /**
   * @description This method handle the table data in case user changes the page or change the total items displayed on that page
   * @param {{
   *  current: string
   *  pageSize: number
   * }} config
   * @returns {string}
   */
  const onTableChange = ({ current, pageSize }) => {
    onChangeQuery({ current, pageSize });
  };

  /**
   * @description Full search - for the moment handles only ean and it is not an autocomplete
   * @param value
   */
  const handleFullTextSearch = (value) => {
    onChangeQuery({
      ...query,
      text: value,
      current: 0
    });
  };

  const onTagRemove = (key) => {
    if (key === TEXT) {
      handleFullTextSearch('');
    }
  };

  return (
    <div>
      <div ref={header} className='py-4 bg-gray-100'>
        <PageTitle>{text('title')}</PageTitle>
        <Row gutter={[16, 16]} className='pt-2'>
          <Col span={6}>
            <SearchInput
              autoFocus
              placeholder={text('searchPlaceholder')}
              onChangeText={handleFullTextSearch}
              defaultValue={query.text}
            />
          </Col>

          <Col span={18}>
            <div className='flex justify-end'>
              {/* TODO enable once API fixed
                <Button
                loading={downloadFile.isLoading}
                onClick={() =>
                  downloadFile.call(
                    '/backoffice/export',
                    'upcoming-orders.xlsx'
                  )
                }
                type='link'
                className='uppercase'
              >
                Download .xls
              </Button> */}
            </div>
          </Col>
        </Row>
        {/* Filter Chips */}
        {(!!query?.text) &&
          <Row className='py-4'>
            <div className='pr-2'>{textFilter('appliedFilter', { count: 1 })}:
              <Chips
                query={query}
                chipKeys={[TEXT]}
                onTagRemove={onTagRemove}
              />
            </div>
          </Row>}
      </div>
      <Table
        dataSource={orders}
        columns={columns}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              setDrawerVisible(true);
              setSelectedOrder(record);
            }
          };
        }}
        pagination={{ ...pagination, showSizeChanger: false }}
        onChange={onTableChange}
        rowClassName='cursor-pointer'
        rowKey='id'
      />

      <Drawer
        visible={!!drawerVisible}
        onClose={() => setDrawerVisible(false)}
        closable
        placement='right'
        className='jc-drawer'
        title={text('orderDetails')}
        width={500}
      >
        <OrderDetails
          selectedOrder={selectedOrder}
          orderType={orderType}
          canSeeCustomerData={canSeeCustomerData}
        />
      </Drawer>
    </div>
  );
};
