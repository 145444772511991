import React, { useEffect, useMemo, useState } from 'react';
import { message } from 'src/services/Messages.service';
import { Row, Col, Input, Form, Divider, Button, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import { useProductDetails } from 'src/modules/products/provider/productDetails.provider';
import { useLanguages } from 'src/modules/global/provider/languages.provider';
import { BackButton } from 'src/components/BackButton';
import { SectionTitle } from 'src/components/forms/SectionTitle';
import { HelperText } from 'src/components/forms/HelperText';
import { WysiwygEditor } from 'src/components/inputs/WysiwygEditor';
import { ProductVariantsFashion } from 'src/modules/products/components/ProductVariantsFashion';
import { ProductAttributeList } from 'src/modules/products/components/ProductAttributeList';
import { ProductsAPI } from 'src/modules/products/api/ProductsAPI';
import { Routes } from 'src/router/Routes.helper';
import { Product } from 'src/services/Product.service';
import { useAttributes } from 'src/hooks/useProvideAttributes';
import { ProductModel } from 'src/modules/products/domain/models/ProductModel';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { DeletionModal } from 'src/components/DeletionModal';
import { VariantModel } from '../domain/models/VariantModel';
import { CategoriesAPI } from 'src/modules/categories/api/CategoriesAPI';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { BrandsAPI } from 'src/modules/brands/api/BrandsAPI';
import { LanguageSelect } from 'src/components/inputs/LanguageSelect';
import { SEPARATOR } from 'src/utils/constants';
import { flatten, nestify } from 'src/utils/objectManipulation';

const { Option } = Select;

const initialFormData = {
  attributeList: [],
  categories: {
    macro: { key: null, label: null },
    micro: { key: null, label: null },
    brand: {
      value: null,
      label: null
    }
  },
  status: null
};

const toCategoryOption = (item) => {
  return {
    ...item,
    value: item.key
  };
};

const toBrandOption = (item) => {
  return {
    ...item,
    value: item.key,
    label: item.name
  };
};

const initMacroTranslation = (form, options, macroKey) => {
  const newMacro = options?.find(opt => opt.value === macroKey);
  form.setFieldsValue({ macro: newMacro });
};

const initMicroTranslation = (form, options, microKey) => {
  const newMicro = options?.find(opt => opt.value === microKey);
  form.setFieldsValue({ micro: newMicro });
};

export const PageProductDetails = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.fashion-edit', t);

  const history = useHistory();
  const [form] = Form.useForm();
  const {
    product,
    filteredProductVariants,
    setFilteredProductVariants,
    setProduct
  } = useProductDetails();
  const { languages, defaultLanguage } = useLanguages();
  const { clearAttributesCache } = useAttributes();
  const [isUpdatePending, setIsUpdatePending] = useState(false);
  const [isDeletePending, setIsDeletePending] = useState(false);
  const [isDeletionModalVisible, setIsDeletionModalVisible] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [selectedMacroKey, setSelectedMacroKey] = useState();
  const [selectedMicroKey, setSelectedMicroKey] = useState();
  const [macroOptions, setMacroOptions] = useState([]);
  const [microOptions, setMicroOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const { userDepartments, userInfo } = useAuth();
  const macroKey = selectedMacroKey || product?.macroCategory?.key;
  const microKey = selectedMicroKey || product?.microCategory?.key;

  useEffect(() => {
    setSelectedLanguage(defaultLanguage);
  }, [defaultLanguage]);

  useEffect(() => {
    initializeMultiLangForm(form, product);
  }, [product]);

  const initBrandOptions = async (department, localBusinessId) => {
    const query = {
      pageNumber: 1,
      limit: 1000,
      filters: {
        department,
        localBusinessId
      }
    };
    try {
      const data = await BrandsAPI.getBrands(query);
      const receivedBrands = data?.items || [];
      const options = receivedBrands.map(toBrandOption);
      setBrandOptions(options);
    } catch (e) {
      message.error(text('onBrandFetchingErr'));
    }
  };

  useEffect(() => {
    initBrandOptions(userDepartments[0], userInfo.localBusiness?.id);
  }, []);

  const initMacroOptions = async () => {
    const res = await CategoriesAPI.getMacroCategories(1, userDepartments[0], selectedLanguage?.language);
    const options = res?.data?.data?.items?.map(toCategoryOption);
    setMacroOptions(options || []);
  };

  const initMicroOptions = async (macroKey) => {
    const res = await CategoriesAPI.getMicroCategories(macroKey, 1, selectedLanguage?.language);
    const options = res?.data?.data?.items?.map(toCategoryOption);
    setMicroOptions(options || []);
  };

  useEffect(() => {
    initMacroTranslation(form, macroOptions, macroKey);
  }, [macroOptions]);

  useEffect(() => {
    initMicroTranslation(form, microOptions, microKey);
  }, [microOptions]);

  useEffect(async () => {
    initMacroOptions();
  }, [selectedLanguage?.language]);

  useEffect(() => {
    if (macroKey) {
      initMicroOptions(macroKey);
    }
  }, [selectedLanguage?.language, selectedMacroKey]);

  useEffect(() => {
    form.setFieldsValue({ language: selectedLanguage?.label });
  }, [product, form, selectedLanguage]);

  useEffect(() => {
    if (!product) return;
    const newFormData = {
      published: Product.getStatus(product.published),
      description: product.descriptions?.[selectedLanguage?.language]?.value,
      storyTelling: product.stories?.[selectedLanguage?.language]?.value,
      attributeList: product.attributeList.map((attr, index) => ({
        id: index,
        key: attr.key,
        attributeId: attr?.id,
        attributeCode: attr.attributeCode,
        code: attr.attributeLocaleName ?? '',
        label: attr.localeName ?? ''
      })),
      brand: {
        id: product.brand?.id,
        key: product.brand.key,
        name: product.brand.name
      },
      categories: {
        macro: {
          key: product.macroCategory.key,
          label: product.macroCategory.localeName
        },
        micro: {
          key: product.microCategory.key,
          label: product.microCategory.localeName
        },
        brand: {
          id: product.brand?.id,
          key: product.brand.key,
          label: product.brand.name
        }
      }
    };
    form.setFieldsValue(newFormData);
    if (!selectedMacroKey) {
      setSelectedMacroKey(product?.macroCategory?.key);
    }
    if (!selectedMicroKey) {
      setSelectedMicroKey(product?.microCategory?.key);
    }
  }, [form, product, selectedLanguage?.language]);

  const showDeletionModal = () => {
    setIsDeletionModalVisible(true);
  };

  const closeDeletionModal = () => {
    setIsDeletionModalVisible(false);
  };

  const navigateToProductsPage = () => {
    history.push(Routes.PATHS.PRODUCTS_FASHION);
  };

  /**
   * @description Delete Product - redirect to products list after the product has been deleted successfully
   * @returns {Promise<void>}
   */
  const handleDeleteProduct = async () => {
    setIsDeletePending(true);
    try {
      await ProductsAPI.deleteProduct(product.id);
      clearAttributesCache();
      // Redirect user to product list page after product delete
      message.success(text('onDeleteOk'));
      navigateToProductsPage();
    } catch (e) {
      message.error(e);
    } finally {
      setIsDeletePending(false);
      setIsDeletionModalVisible(false);
    }
  };

  const requiredLanguage = useMemo(() => (
    languages.find(language => language.defaultLanguage)
  ), [languages]);

  /**
   * @description Form save
   * @param values
   */
  const onSubmit = async (data) => {
    const formValues = form.getFieldsValue(true);

    const { names, descriptions, stories, notes } =
      nestify(formValues, ['names', 'descriptions', 'stories', 'notes']);

    if (!names?.[requiredLanguage.language]?.value) {
      message.error(text('onMissingLocalizedValue', { language: requiredLanguage.language, value: text('name') }));
      return;
    }

    if (!descriptions?.[requiredLanguage.language]?.value) {
      message.error(text('onMissingLocalizedValue', { language: requiredLanguage.language, value: text('description') }));
      return;
    }

    clearAttributesCache();
    setIsUpdatePending(true);
    try {
      // Update product status
      await ProductsAPI.updateProductStatus(product.id, data.published);

      // Update product
      data.names = names;
      data.descriptions = descriptions;
      data.stories = stories;
      data.notes = notes;
      data.macro = form.getFieldValue('macro');
      data.micro = form.getFieldValue('micro');
      data.brand = form.getFieldValue('brand');

      const res = await ProductsAPI.updateProductDetails(product.id, data);

      // TODO attributeLocaleName e localeName non campi persistiti sul db e vengono spesso calcolati a runtime - do a request to get again the product detail data
      setProduct(new ProductModel(res));
      message.success(text('onUpdateOk'));
      navigateToProductsPage();
    } catch (e) {
      message.error(e);
    } finally {
      setIsUpdatePending(false);
    }
  };

  const handleVariantUpdate = async (selectedVariant, variantFormData, variantGalleryFiles, addedVariantGalleryFiles, deletedVariantGalleryFiles, selectedVariantAvailabilities) => {
    clearAttributesCache();
    setIsUpdatePending(true);
    try {
      const coverImage = variantGalleryFiles.find(galleryFile => galleryFile.info.type === 'cover');
      await ProductsAPI.updateVariantCoverImage(product.id, selectedVariant.id, coverImage, addedVariantGalleryFiles, deletedVariantGalleryFiles);
      const galleryImages = variantGalleryFiles.filter(galleryFile => galleryFile.info.type !== 'cover');
      // Update variant Gallery
      await ProductsAPI.updateVariantGallery(product.id, selectedVariant.id, galleryImages, addedVariantGalleryFiles, deletedVariantGalleryFiles);
      // Update availabilities
      await ProductsAPI.updateVariantAvailability(
        product.id,
        selectedVariant.id,
        variantFormData.availabilities,
        selectedVariantAvailabilities
      );
      // Update other variant data
      const apiVariant = await ProductsAPI.updateVariant(product.id, selectedVariant, variantFormData, VariantModel);
      setFilteredProductVariants((variants) =>
        variants.map((variant, i) => {
          if (variant.id !== selectedVariant.id) return variant;
          return { ...filteredProductVariants[i], ...apiVariant };
        })
      );
      message.success(text('onVariantUpdateOk'));
      setIsUpdatePending(false);
    } catch (e) {
      setIsUpdatePending(false);
      message.error(e);
    }
  };

  const handleLanguageChange = (_, languageOption) => {
    setSelectedLanguage({ language: languageOption.value, label: languageOption.label });
  };

  const handleQuickVariantUpdate = async (selectedVariant, variantFormData) => {
    try {
      // Update product status
      await ProductsAPI.updateVariantStatus(product.id, selectedVariant.id, variantFormData.published);
      // Update other variant data
      const apiVariant = await ProductsAPI.updateVariant(product.id, selectedVariant, variantFormData, VariantModel);
      setFilteredProductVariants((variants) =>
        variants.map((variant, i) => {
          if (variant.id !== selectedVariant.id) return variant;
          return { ...filteredProductVariants[i], ...apiVariant };
        })
      );
      message.success(text('onVariantUpdateOk'));
    } catch (e) {
      message.error(e);
    }
  };

  const handleSelectMacro = (_, newMacro) => {
    setSelectedMacroKey(newMacro.key);
    setSelectedMicroKey(undefined);
    form.setFieldsValue({ macro: newMacro });
    form.setFieldsValue({ micro: undefined });
  };

  const handleSelectMicro = (_, newMicro) => {
    setSelectedMicroKey(newMicro.key);
    form.setFieldsValue({ micro: newMicro });
  };

  const handleSelectBrand = (_, newBrand) => {
    form.setFieldsValue({ brand: newBrand });
  };

  return (
    <div className='py-4  rowHoverCursorStyle'>
      <div className='text-lg'>{product.name}</div>
      <HelperText text={text('pageDescription')} />
      <BackButton
        iconChevron
        className='mt-4 mb-8'
        onClick={navigateToProductsPage}
        title={text('backToProducts')}
      />
      {/* Product Information Section */}
      <Form
        onFinish={onSubmit}
        form={form}
        initialValues={initialFormData}
        layout='vertical'
        autoComplete='off'
        scrollToFirstError
      >
        {/* Language */}
        <Row gutter={[16, 16]} className='pt-8'>
          <Col span={8}>
            <LanguageSelect
              label={text('language')}
              onSelect={handleLanguageChange}
            />
          </Col>
        </Row>

        {/* Name */}
        <Row gutter={[16, 16]} className='pt-4'>
          <Col span={8}>
            <NameInput selectedLanguage={selectedLanguage?.language} />
          </Col>
          {/* Published */}
          <Col span={8}>
            <Form.Item name='published' label={text('status')}>
              <Select>
                {Product.statusOptions.map((status, i) => (
                  <Option key={`${status}-${i}`} value={status}>
                    {status}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className='pt-4'>
          {/* Description */}
          <Col span={8}>
            <WysiwygFormItem
              form={form}
              label={text('description')}
              name={`descriptions${SEPARATOR}${selectedLanguage?.language}${SEPARATOR}value`}
            />
          </Col>
          {/* StoryTelling */}
          <Col span={8}>
            <WysiwygFormItem
              form={form}
              label={text('storyTelling')}
              name={`stories${SEPARATOR}${selectedLanguage?.language}${SEPARATOR}value`}
            />
          </Col>
          {/* Notes */}
          <Col span={8}>
            <WysiwygFormItem
              form={form}
              label={text('notes')}
              name={`notes${SEPARATOR}${selectedLanguage?.language}${SEPARATOR}value`}
            />
          </Col>
        </Row>

        {/* Variants */}
        <Divider />
        <SectionTitle title={text('variants')} className='pb-4' />
        <HelperText text={text('variantsDescription')} />
        {filteredProductVariants &&
          <ProductVariantsFashion
            language={selectedLanguage}
            variants={filteredProductVariants}
            productId={product.id}
            onDeleteVariant={() => { }}
            onQuickEditVariant={handleQuickVariantUpdate}
            onEditVariant={handleVariantUpdate}
            isUpdatePending={isUpdatePending}
          />}

        {/* Categories */}
        <Divider />
        <SectionTitle title={text('categories')} className='pb-4' />
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Form.Item
              name={['macro', 'label']}
              label={text('macro')}
              rules={[{ required: true, message: text('macroRequired') }]}
            >
              <Select
                options={macroOptions}
                onSelect={handleSelectMacro}
                placeholder={text('selectMacro')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={['micro', 'label']}
              label={text('micro')}
              rules={[{ required: true, message: text('microRequired') }]}
            >
              <Select
                options={microOptions}
                onSelect={handleSelectMicro}
                placeholder={text('selectMicro')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={['brand', 'name']}
              label={text('brand')}
              rules={[{ required: true, message: text('brandRequired') }]}
            >
              <Select
                options={brandOptions}
                onSelect={handleSelectBrand}
                placeholder={text('selectbrand')}
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Attributes */}
        <Divider />
        <SectionTitle title={text('attributes')} className='pb-4' />
        <Form.Item name='attributeList'>
          <ProductAttributeList
            language={selectedLanguage}
            currentDepartment='fashion'
          />
        </Form.Item>
        <Divider />
        {/* Delete Product */}
        <Button
          type='danger'
          htmlType='button'
          className='uppercase mr-2'
          loading={isDeletePending}
          onClick={showDeletionModal}
        >
          {text('deleteProduct')}
        </Button>
        <Button type='primary' className='uppercase' htmlType='submit' loading={isUpdatePending}>
          {text('updateProduct')}
        </Button>
      </Form>

      <DeletionModal
        visible={isDeletionModalVisible}
        onOk={handleDeleteProduct}
        onCancel={closeDeletionModal}
      >
        <div>{text('confirmDeletion')}</div>
      </DeletionModal>
    </div>
  );
};

const NameInput = ({ selectedLanguage }) => {
  const { t } = useTranslation();
  const text = textFrom('pages.fashion-edit', t);

  return (
    <Form.Item
      name={`names${SEPARATOR}${selectedLanguage}${SEPARATOR}value`}
      label={text('name')}
    >
      <Input placeholder={text('namePlaceholder')} />
    </Form.Item>
  );
};

const WysiwygFormItem = ({ form, name, label }) => (
  <Form.Item
    name={name}
    label={label}
  >
    <WysiwygEditor
      name={name}
      initialValue={form.getFieldValue(name)}
      onChange={(name, value) => { form.setFieldsValue({ [name]: value }); }}
    />
  </Form.Item>
);

const initializeMultiLangForm = (form, product) => {
  form.setFieldsValue({
    ...flatten(product, ['names', 'descriptions', 'stories', 'notes'])
  });
};
