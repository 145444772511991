import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { LocationAPI } from '../api/LocationAPI';

const { Option } = Select;

export const LocationForm = ({
  form,
  buttons,
  onFinish
}) => {
  const { t } = useTranslation();
  const text = textFrom('components.locationForm', t);
  const [countryList, setCountryList] = useState([]);

  useEffect(async () => {
    const countries = await LocationAPI.getCountries();
    setCountryList(countries);
  }, []);

  const handleSelect = (value) => {
    form.setFieldsValue({ country: value });
  };

  return (
    <div className='py-4'>
      <Form
        form={form}
        onFinish={onFinish}
        layout='vertical'
        autoComplete='off'
        scrollToFirstError
      >
        <Row gutter={16} className='pt-4'>
          <Col span={7}>
            <Form.Item
              name='name'
              label={text('name.label')}
              rules={[{ required: true, message: text('name.message') }]}
            >
              <Input placeholder={text('name.placeholder')} />
            </Form.Item>

            <Form.Item
              name='city'
              label={text('city.label')}
              rules={[{ required: true, message: text('city.message') }]}
            >
              <Input placeholder={text('city.placeholder')} />
            </Form.Item>

            <Form.Item
              name='address'
              label={text('address.label')}
              rules={[{ required: true, message: text('address.message') }]}
            >
              <Input placeholder={text('address.placeholder')} />
            </Form.Item>

            <Form.Item
              name='positionX'
              label={text('positionX.label')}
              rules={[{ required: true, message: text('positionX.message') }]}
            >
              <Input type='number' placeholder={text('positionX.placeholder')} />
            </Form.Item>
          </Col>

          <Col span={7}>
            <Form.Item
              name='country'
              label={text('country.label')}
              rules={[{ required: true, message: text('country.message') }]}
            >
              <Select
                className='hideTags'
                showArrow
                getPopupContainer={trigger => trigger.parentNode}
                showSearch
                mode='tags'
                optionFilterProp='children'
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                onSelect={(e) => handleSelect(e)}
              >
                {countryList.map((country, i) => (
                  <Option key={`${country.id}`} value={country.localeName}>
                    {country.localeName}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name='zip'
              label={text('zip.label')}
              rules={[{ required: true, message: text('zip.message') }]}
            >
              <Input placeholder={text('zip.placeholder')} />
            </Form.Item>

            <Form.Item
              name='number'
              label={text('number.label')}
              rules={[{ required: true, message: text('number.message') }]}
            >
              <Input type='text' placeholder={text('number.placeholder')} />
            </Form.Item>

            <Form.Item
              name='positionY'
              label={text('positionY.label')}
              rules={[{ required: true, message: text('positionY.message') }]}
            >
              <Input type='number' placeholder={text('positionY.placeholder')} />
            </Form.Item>
          </Col>

          <Col span={7}>
            <Form.Item
              label={
                <span>
                  {text('description.label')}<span className='text-red-500'> *</span>
                </span>
              }
              name='description'
              required={false}
              rules={[{ required: true, message: text('description.message') }]}
            >
              <Input.TextArea
                rows='6'
                showCount
                maxLength={1500}
                placeholder={text('description.placeholder')}
              />
            </Form.Item>
          </Col>
        </Row>

        {buttons}

      </Form>
    </div>
  );
};
