import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Select, message, Checkbox } from 'antd';
import { Routes } from 'src/router/Routes.helper';
import { useForm } from 'antd/lib/form/Form';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { textFrom } from 'src/utils/textFrom';
import { toOption } from 'src/utils/utils';
import { PageTitle } from 'src/components/PageTitle';
import { useHistory } from 'react-router-dom';
import { BackButton } from 'src/components/BackButton';
import { SubmitButton } from 'src/components/buttons/SubmitButton';
import { DefaultButton } from 'src/components/buttons/DefaultButton';
import { WarehousesAPI } from '../api/WarehousesAPI';
import { useTranslation } from 'react-i18next';
import { WAREHOUSE_TYPES } from '../domain/models/warehouseTypes';

const WAREHOUSE_TYPE_OPTIONS = WAREHOUSE_TYPES.map(toOption);
const PRIORITY_OPTIONS = [
  { value: 1, label: '1 High priority' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5 Medium priority' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 0, label: '0 No priority' }
];

export const PageCreateWarehouse = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.warehouses/create', t);

  const [form] = useForm();
  const history = useHistory();
  const { userInfo } = useAuth();
  const [isPending, setIsPending] = useState(false);
  const [isEcommerce, setIsEcommerce] = useState(true);

  const navigateToWarehousesPage = () => {
    history.push(Routes.PATHS.WAREHOUSES);
  };

  const toggleIsEcommerce = () => {
    setIsEcommerce(!isEcommerce);
  };

  const onFinish = async (values) => {
    setIsPending(true);
    const data = {
      name: values.name,
      type: values.type,
      company: values.companyName,
      taxId: values.taxId,
      address: {
        streetAddress: values.companyAddress
      },
      email: values.companyEmail,
      ecommerce: isEcommerce,
      fulfillmentPriority: values.priority,
      backofficeUsers: [userInfo.email],
      externalWarehouseIdentifier: values.externalWarehouseIdentifier
    };
    try {
      await WarehousesAPI.createWarehouse(data);
      message.success(text('onCreateOK'));
      navigateToWarehousesPage();
    } catch (e) {
      message.error(text('onCreateKO'));
    } finally {
      setIsPending(false);
    }
  };

  // FIXME: remove this when type=MANUAL must be no longer hardcoded
  useEffect(() => {
    if (form) {
      form.setFieldsValue({ type: 'MANUAL' });
    }
  }, [form]);

  return (
    <div className='py-4'>
      <PageTitle>{text('pageTitle')}</PageTitle>

      <BackButton
        iconChevron
        className='mt-4 mb-8'
        title={text('backButton')}
        onClick={navigateToWarehousesPage}
      />

      <Form
        form={form}
        layout='vertical'
        autoComplete='off'
        scrollToFirstError
        onFinish={onFinish}
      >
        <Row gutter={20} className='mb-4'>
          <Col xs={7}>
            <Form.Item
              name='name'
              label={text('name')}
              rules={[{ required: true, message: text('nameRequired') }]}
            >
              <Input placeholder={text('namePlaceholder')} />
            </Form.Item>
          </Col>

          <Col xs={5}>
            <Form.Item
              name='type'
              label={text('type')}
              rules={[{ required: true, message: text('typeRequired') }]}
            >
              <Select
                disabled
                options={WAREHOUSE_TYPE_OPTIONS}
                placeholder={text('typePlaceholder')}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20} className='mb-4'>
          <Col xs={7}>
            <Form.Item
              name='companyName'
              label={text('companyName')}
            >
              <Input placeholder={text('companyNamePlaceholder')} />
            </Form.Item>
          </Col>

          <Col xs={5}>
            <Form.Item
              name='taxId'
              label={text('taxId')}
            >
              <Input placeholder={text('taxIdPlaceholder')} />
            </Form.Item>
          </Col>

          <Col xs={7}>
            <Form.Item
              name='companyAddress'
              label={text('companyAddress')}
            >
              <Input placeholder={text('companyAddressPlaceholder')} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20} className='mb-4'>
          <Col xs={7}>
            <Form.Item
              name='companyEmail'
              label={text('companyEmail')}
            >
              <Input placeholder={text('companyEmailPlaceholder')} />
            </Form.Item>
          </Col>

          <Col xs={5}>
            <Form.Item
              name='eCommerce'
              label=' '
            >
              <div className='checkbox-one-line'>
                <div>{text('eCommerce')}</div>
                <Checkbox
                  disabled
                  checked={isEcommerce}
                  onClick={toggleIsEcommerce}
                />
              </div>
            </Form.Item>
          </Col>

          <Col xs={7}>
            <Form.Item
              name='priority'
              label={text('priority')}
            >
              <Select
                defaultValue={{ value: 0, label: '0 No priority' }}
                options={PRIORITY_OPTIONS}
                placeholder={text('priorityPlaceholder')}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20} className='mb-4'>
          <Col xs={7}>
            <Form.Item
              name='externalWarehouseIdentifier'
              label={text('externalWarehouseIdentifier')}
            >
              <Input placeholder={text('externalWarehouseIdentifierPlaceholder')} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20} justify='end' className='mt-12'>
          <Col>
            <DefaultButton label={text('cancel')} onClick={navigateToWarehousesPage} />
          </Col>
          <Col>
            <SubmitButton label={text('createNewWarehouse')} loading={isPending} disabled={isPending} />
          </Col>
        </Row>
      </Form>
    </div>
  );
};
