import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  useLocation,
  useHistory
} from 'react-router-dom';
import {
  Row,
  Col,
  Input,
  Select,
  Form,
  DatePicker,
  TimePicker,
  InputNumber,
  message,
  Button,
  Progress,
  Divider,
  Radio,
  Space,
  Modal
} from 'antd';
import { BackButton } from 'src/components/BackButton';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { useEventDetails } from 'src/modules/products/provider/eventDetails.provider';
import moment from 'moment';
import { EventsAPI } from '../api/EventsAPI';
import { DurationPicker } from '../components/DurationPicker';
import { v4 as uuidv4 } from 'uuid';
import { SectionTitle } from 'src/components/forms/SectionTitle';
import { HelperText } from 'src/components/forms/HelperText';
import { ProductVariantsEvent } from 'src/modules/products/components/ProductVariantsEvent';
import { AdditionalPropertiesList } from '../components/AdditionalPropertiesList';
import { ProductAttributeList } from 'src/modules/products/components/ProductAttributeList';
import { ImageGalleryEvents } from '../components/ImageGalleryEvents';
import { getCorrectMinutesAndHours, isBefore, MODE } from '../../../utils/utils';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { Routes } from 'src/router/Routes.helper';
import { LocationAPI } from '../../../modules/locations/api/LocationAPI';
import { useAttributes } from 'src/hooks/useProvideAttributes';
import { useLanguages } from 'src/modules/global/provider/languages.provider';
import { LanguageSelect } from 'src/components/inputs/LanguageSelect';
import { flatten, nestify } from 'src/utils/objectManipulation';
import { PageTitle } from 'src/components/PageTitle';
import { SEPARATOR } from 'src/utils/constants';
import { TrimmedDescription } from '../components/TrimmedDescription';
import mcubeLogoTransparent from 'src/images/logo-mcube_transparent.png';
import { SCOPE } from 'src/utils/scope-utils';

const { Option } = Select;
const { PLATFORM, TENANT } = SCOPE;
const COVER = 'cover';
const DETAIL = 'detail';

export const PageEditEvent = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.event/edit', t);

  const {
    event,
    availableStartDates,
    availableEventMicroCategories,
    sizeAttributes,
    productVariants,
    setProductVariants,
    warehouses
  } = useEventDetails();

  const { getAttributes } = useAttributes();

  const [formValues, setFormValues] = useState({});
  const [mode, setMode] = useState(MODE.EDIT);
  const [form] = useForm();
  const [isSingleVariant, setIsSingleVariant] = useState(true);
  const [, setNewVariantAdded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [productGalleryFiles, setProductGalleryFiles] = useState([]);
  const [overMidnight, setOverMidnight] = useState(false);
  const [ticketPercentage, setTicketPercentage] = useState(0);
  const [ticketFraction, setTicketFraction] = useState('');
  const [originalAvailability, setOriginalAvailability] = useState(0);
  const [isDeletionUnderway, setIsDeletionUnderway] = useState(false);
  const [isDeletionModalVisible, setIsDeletionModalVisible] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const { languages, defaultLanguage } = useLanguages();
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [isWareHouseSelectVisible, setIsWareHouseSelectVisible] = useState(false);
  const [startTime, setStartTime] = useState(null);

  const currentDescription = useWatch(`descriptions${SEPARATOR}${selectedLanguage}`, form);

  useEffect(() => {
    setSelectedLanguage(defaultLanguage.language);
  }, [defaultLanguage]);

  const isBeforeToday = isBefore(moment('00:00', 'h:mm').utc());

  const navigateToEventsPage = () => {
    const startDate = event.attributes?.start_date;
    const eventDate = startDate ? moment(startDate).utc() : undefined;
    const route = isBeforeToday(eventDate) ? Routes.PATHS.PRODUCTS_EVENT_PAST : Routes.PATHS.PRODUCTS_EVENT_UPCOMING;
    history.push({ pathname: route });
  };

  const handleLanguageSelection = (languageId) => {
    setSelectedLanguage(languageId);
  };

  const statusOptions = [
    {
      value: 'Draft',
      name: 'Draft'
    },
    {
      value: 'Published',
      name: 'Published'
    }
  ];

  const { selectedUserScope, canCreateEvents } = useAuth();

  const requiredLanguage = useMemo(() => {
    return languages.find(language => language.defaultLanguage);
  }, [languages]);

  const onValuesChange = () => {
    const values = form.getFieldsValue();
    setFormValues(values);
  };

  useEffect(() => {
    if (event && (selectedUserScope === PLATFORM || selectedUserScope === TENANT)) {
      setIsWareHouseSelectVisible(true);
    } else {
      setIsWareHouseSelectVisible(false);
    }
  }, [event, selectedUserScope, setIsWareHouseSelectVisible]);

  useEffect(() => {
    initializeMultiLangForm(form, event);
  }, [event]);

  const location = useLocation();

  useEffect(async () => {
    const countries = await LocationAPI.getLocations({ limit: 999, pageNumber: 1 });
    if (countries?.data?.length > 0) {
      setLocationList(countries.data);
    }
  }, []);

  useEffect(() => {
    if (defaultLanguage) {
      form.setFieldsValue({ language: defaultLanguage.label });
    }
  }, [event, form, defaultLanguage]);

  useEffect(() => {
    if (location?.state?.mode) {
      setMode(location.state.mode);
    }
  }, [location]);
  const history = useHistory();

  useEffect(() => {
    calculateOriginalAvailability();
    const availabilityTotal = productVariants.reduce(function (a, b) {
      return a + (+b.initialAvailability || +b.availableSeatsQty);
    }, 0);
    const availableSeatsQtyTotal = productVariants.reduce(function (a, b) {
      return a + (+b.availableSeatsQty);
    }, 0);
    const availabilityRelative = availabilityTotal - availableSeatsQtyTotal;

    const percentage = Math.round((originalAvailability || availabilityRelative) / availabilityTotal * 100);
    const fraction = `${originalAvailability || availabilityRelative}/${availabilityTotal}`;
    setTicketFraction(fraction);
    setTicketPercentage(percentage);

    if (productVariants.length > 1) {
      setIsSingleVariant(false);
    }
  }, [productVariants]);

  const getAttributeLabels = async (attr) => {
    const page = 1;
    const res = await getAttributes(attr, page, selectedLanguage, null, 'asc', 1000);
    return res.data.data.items;
  };

  const getAttributesList = async (attributes) => {
    const attributesList = [];
    if (attributes) {
      Object.keys(attributes).filter((key) => !['location', 'start_date', 'durationInMin', 'start_time'].includes(key)).map(async (attr, index) => {
        const labels = await getAttributeLabels(attr);
        // Here is where we set the no-date / no-series as null on LOAD
        let value = event.attributes[attr] || '';
        if (event.attributes[attr] === 'no-series') {
          value = null;
        }
        if (event.attributes[attr] === 'no-date') {
          value = null;
        }
        attributesList.push({
          id: index,
          code: attr,
          key: value,
          label: labels.find(label => label.key === value)?.label
        });
        form.setFieldsValue({
          attributeList: attributesList
        });
      });
    }
  };

  useEffect(async () => {
    if (event) {
      const newSelectedLocation = {
        key: null,
        name: event.eventPlace.name
      };
      getAttributesList(event.attributes);
      setSelectedLocation(newSelectedLocation);
      setProductGalleryFiles(event?.images.map((imageUrl, index) => ({
        active: true,
        filename: null,
        id: index,
        imageURL: imageUrl,
        url: imageUrl,
        thumbUrl: imageUrl,
        sizeType: null,
        tag: null,
        type: index === 0 ? COVER : DETAIL,
        status: 'done'
      })) || []);
      form.setFieldsValue({
        price: event.price,
        ticketsAvailable: event.initialAvailability,
        names: event.names,
        status: event.published ? 'Published' : 'Draft',
        category: event.microCategory,
        descriptions: event.descriptions,
        place: event.eventPlace.name,
        startTime: moment(event.attributes.start_time, 'HH:mm'),
        endTime: moment(event.attributes.start_time, 'HH:mm').add(
          event.attributes.durationInMin,
          'minutes'
        ),
        date: moment(new Date(event.attributes.start_date)),
        duration: event.attributes.durationInMin,
        additionalProperties: event?.additionalProperties?.map((property, index) => ({ ...property, id: index })) || []
      });
      const endDay = moment(`${event.attributes.start_date} ${event.attributes.start_time}`, 'YYYY-MM-DD HH:mm').add(event.attributes.durationInMin, 'minutes').format('DD-MM-YYYY');
      const endTime = moment(event.attributes.start_time, 'HH:mm').add(event.attributes.durationInMin, 'minutes').format('HH:mm');
      const endDate = `${endDay} ${endTime}`;
      form.setFieldsValue({ endDate });
      setStartTime(moment(event.attributes.start_time, 'HH:mm'));
    }
  }, [form, event?.id]);

  const handleSelect = (value) => {
    const selectedLocation = locationList.find(location => location.name === value);
    if (selectedLocation) {
      setSelectedLocation(selectedLocation);
      form.setFieldsValue({ place: selectedLocation.name });
    }
  };

  /**
   * @description Handle Gallery update
   * @param file
   * @param fileList
   */
  const handleGalleryUpdate = (file, fileList, type) => {
    const prevCoverGalleryFile = productGalleryFiles.filter(item => item.type === COVER);
    const prevDetailsGalleryFiles = productGalleryFiles.filter(item => item.type !== COVER);
    // if it's a deletion of a detail, allow it
    // set the cover as the first item of the array
    if (fileList.length < prevDetailsGalleryFiles.length && type !== COVER) {
      setProductGalleryFiles([...prevCoverGalleryFile, ...fileList]);
    }
  };

  const calculateOriginalAvailability = () => {
    if (!originalAvailability && productVariants.length > 0) {
      const originalSeatQty = productVariants.reduce((a, b) => {
        return a + +b.availableSeatsQty;
      }, 0);
      const originalInitialAv = productVariants.reduce((a, b) => {
        return a + +b.initialAvailability;
      }, 0);
      setOriginalAvailability(originalInitialAv - originalSeatQty);
    }
  };

  const customRequest = async (info, type) => {
    const result = await EventsAPI.uploadImage(info.file);
    let index = -1;
    if (type === COVER) {
      index = productGalleryFiles.findIndex((item) => item.type === COVER);
    } else {
      index = productGalleryFiles.findIndex((item) => item.uid === info.file.uid);
    }
    const newImage = {
      active: result.data.images[0].active,
      filename: result.data.images[0].filename,
      id: index,
      imageURL: result.data.images[0].imageURL,
      url: result.data.images[0].imageURL,
      thumbUrl: result.data.images[0].imageURL,
      sizeType: result.data.images[0].sizeType,
      tag: result.data.images[0].tag,
      type: type,
      status: 'done'
    };
    const newProductGalleryFiles = [...productGalleryFiles];
    if (index > -1) {
      newProductGalleryFiles[index] = newImage;
    } else {
      newProductGalleryFiles.push(newImage);
    }
    setProductGalleryFiles(newProductGalleryFiles);
  };

  const onFinish = async (values) => {
    const formValues = form.getFieldsValue(true); // Get all the values, even the unmounted ones
    const {
      names,
      descriptions
    } = nestify(formValues, ['names', 'descriptions']);

    if (!names?.[requiredLanguage.language]) {
      message.error(text('onMissingLocalizedValue', { language: requiredLanguage.language, value: text('name') }));
      return;
    }

    if (!descriptions?.[requiredLanguage.language]) {
      message.error(text('onMissingLocalizedValue', { language: requiredLanguage.language, value: text('description') }));
      return;
    }

    const {
      category,
      date,
      startTime,
      duration,
      price,
      ticketsAvailable,
      status,
      additionalProperties,
      attributeList
    } = values;
    if (duration === 0) {
      form.setFields([
        {
          name: 'duration',
          errors: [text('onDurationTooShort')]
        }
      ]);
      return;
    }

    const eventData = {
      names: names,
      descriptions: descriptions,
      microCategory: category,
      macroCategory: availableEventMicroCategories.find(item => item.value === category).macroCategory,
      availableSeatsQty: event.availableSeatsQty,
      price,
      currency: 'EUR',
      // TODO check the event id clashing
      eventId: mode === 'edit' ? event.eventId : uuidv4(),
      published: status === 'Published',
      manufacturerCode: mode === 'edit' ? event.manufacturerCode : null,
      attributes: {
        start_date: moment(date).format('YYYY-MM-DD'),
        start_time: moment(startTime).format('HH:mm'),
        durationInMin: duration,
        ...attributeList.reduce((accumulator, value) => {
          return { ...accumulator, [value.code]: value.key };
        }, {})
      },
      images: productGalleryFiles?.length > 0 ? productGalleryFiles.map(image => image.imageURL) : [mcubeLogoTransparent],
      tags: {},
      eventPlace: {
        name: selectedLocation.name,
        key: selectedLocation.key,
        description: ''
      },
      warehouseId: event.warehouseId,
      // TODO transfer additional properties
      additionalProperties: additionalProperties,
      initialAvailability: ticketsAvailable
    };

    // Here is where we parse the data and set it to no-series || no-date in case nothing is past
    const seriesStartDate = attributeList.find((itm) => itm.code === 'series_start_date');
    if (seriesStartDate) {
      eventData.attributes.series_start_date = seriesStartDate.value ? moment(seriesStartDate.value).format('YYYY-MM-DD') : seriesStartDate.key || 'no-date';
    }
    const seriesEndDate = attributeList.find((itm) => itm.code === 'series_end_date');
    if (seriesEndDate) {
      eventData.attributes.series_end_date = seriesEndDate.value ? moment(seriesEndDate.value).format('YYYY-MM-DD') : seriesEndDate.key || 'no-date';
    }
    const seriesID = attributeList.find((itm) => itm.code === 'series_id');
    if (seriesID) {
      eventData.attributes.series_id = seriesID.value || seriesID.key || 'no-series';
    }

    // IF START & END EXISTS
    if (eventData.attributes.series_start_date !== 'no-date' && eventData.attributes.series_end_date !== 'no-date') {
      form.setFields([
        {
          name: 'attributeList',
          errors: eventData.attributes.series_end_date < eventData.attributes.series_start_date ? ['series_start_date cant be greater than series_end_date!'] : []
        }
      ]);
      if (eventData.attributes.series_end_date < eventData.attributes.series_start_date) {
        return;
      }
    }

    const calcAvSeatsQty = (initialAv, originalInitialAv, avSeatsQty) => {
      if (!originalInitialAv && !(avSeatsQty == null)) {
        return initialAv;
      } else if (!originalInitialAv && avSeatsQty >= 0) {
        return avSeatsQty;
      } else if (mode === 'duplicate') {
        return initialAv;
      } else {
        return initialAv - originalInitialAv + avSeatsQty;
      }
    };

    const upsertEventWithVariantsDetail = async (eventData, variant, manufacturerCode = null) => {
      const newEventData = { ...eventData };
      newEventData.availableSeatsQty = (isSingleVariant) ? calcAvSeatsQty(newEventData.initialAvailability, event.initialAvailability, event.availableSeatsQty) : calcAvSeatsQty(variant.initialAvailability, variant.originalInitialAv, variant.availableSeatsQty);
      newEventData.price = (isSingleVariant) ? eventData.price : variant.price;
      newEventData.attributes.location = variant.attributes.location;
      newEventData.manufacturerCode = manufacturerCode;
      newEventData.initialAvailability = (isSingleVariant) ? newEventData.initialAvailability : variant.initialAvailability;
      await EventsAPI.createEvent(newEventData);
    };
    setIsLoading(true);
    if (mode === 'edit') {
      // edit event
      try {
        const reversedVariants = productVariants?.slice().reverse();
        if (reversedVariants.length === 1) {
          reversedVariants[0].price = price;
        }
        // iterates over variants and doesnt include the default one
        for (let i = 0; i < productVariants?.length; i++) {
          await upsertEventWithVariantsDetail(eventData, reversedVariants[i], reversedVariants[i].manufacturerCode);
        }
        setIsLoading(false);
        message.success(text('onEditOk'));
        navigateToEventsPage();
      } catch (error) {
        setIsLoading(false);
        message.error(text('onError'));
      }
    } else {
      // duplicate event (create)
      try {
        // check if event has multiple areas and create variants
        if (productVariants?.length > 0) {
          for (let index = 0; index < productVariants?.length; index++) {
            await upsertEventWithVariantsDetail(eventData, productVariants[index]);
          }
        } else {
          // create event with default variant
          // add default location value
          const eventDataWithDefaultLocation = { ...eventData };
          eventDataWithDefaultLocation.attributes.location = 'no-location';
          await EventsAPI.createEvent(eventDataWithDefaultLocation);
        }
        message.success(text('onCreateOk'));
        setIsLoading(false);
        navigateToEventsPage();
      } catch (error) {
        setIsLoading(false);
        message.error(text('onError'));
      }
    }
  };

  const filterStartDates = current => {
    let filterDate = false;
    if (availableStartDates.length > 0) {
      if (current && moment(current).isAfter(moment())) {
        filterDate = false;
      } else {
        filterDate = true;
      }
    }
    return filterDate;
  };

  const checkDurationField = () => {
    const values = form.getFieldsValue();
    if (values.duration === 0) {
      setTimeout(() => {
        form.setFields([
          {
            name: 'duration',
            errors: [text('onDurationTooShort')]
          }
        ]);
      }, 200);
    }
  };

  const convertToMinutes = (days, hours, minutes) => (days * 1440 + hours * 60 + minutes);

  const handleDurationChange = duration => {
    const { days, hours, minutes } = duration;

    form.setFieldsValue({ duration: convertToMinutes(days || 0, hours || 0, minutes || 0) });
    // calculate the end date
    const { startTime, date } = form.getFieldsValue();

    const min = startTime?.minute() || 0;
    const hour = startTime?.hour() || 0;
    const endDay = moment(date).startOf('day')
      .add(days, 'days')
      .add(hours, 'hours')
      .add(minutes, 'minutes')
      .add(0, 'seconds');
    const endDate = moment(endDay).add(hour, 'hours').add(min, 'minutes');
    const formattedDay = moment(endDate).format('DD-MM-YYYY');
    const formattedHours = moment(endDate).format('HH:mm');

    const valueDate = `${formattedDay} ${formattedHours}`;

    const thresholdDate = moment(endDay).add(1, 'days').format('DD-MM-YYYY');
    const thresholdEndDate = endDate.format('DD-MM-YYYY');

    if (thresholdEndDate === thresholdDate) {
      setOverMidnight(true);
    } else {
      setOverMidnight(false);
    }
    if (!startTime || !date) {
      form.setFieldsValue({ endDate: '' });
    } else {
      form.setFieldsValue({ endDate: valueDate });
    }
    return startTime ? getCorrectMinutesAndHours(startTime._d) : null;
  };

  const disabledStartMinutes = [];
  const gap = 15;
  for (let index = 0; index < 60; index++) {
    if (index % gap !== 0) {
      disabledStartMinutes.push(index);
    }
  }

  const handleRadioChange = (event) => {
    setIsSingleVariant(event.target.value);
  };

  const clearStartDateAndSetStartTime = (newStartTime) => {
    setStartTime(newStartTime);
    form.setFieldsValue({
      duration: {
        days: 0,
        hours: 0,
        minutes: 0
      }
    });
  };

  const warehouseName = useMemo(() => {
    if (!event) {
      return '';
    }
    const warehouse = warehouses.find((warehouse) => event.warehouseId === warehouse.warehouseIdentifier);
    return warehouse?.name || '';
  }, [warehouses, event]);

  const WarehouseInput = () => (
    <Form.Item
      label={text('warehouse')}
    >
      <Input
        disabled
        value={warehouseName}
      />
    </Form.Item>
  );

  const calculateLimits = (variants, availabilityTotal) => {
    let calcMax = 0;
    let calcAvTot = availabilityTotal || 0;
    if (Array.isArray(variants)) {
      variants.forEach((pv) => {
        if (pv.initialAvailability) {
          calcMax += pv.initialAvailability;
        } else {
          calcMax += pv.availableSeatsQty;
          calcAvTot += pv.availableSeatsQty;
        }
      });
    } else {
      calcMax = variants.initialAvailability;
      calcAvTot = +variants.availableSeatsQty;
    }
    const limits = {
      max: calcMax || variants.availableSeatsQty,
      availabilityTotal: calcAvTot
    };
    return limits;
  };

  const calculateProgressPercentage = useCallback((variants, availabilityTotal) => {
    const limits = calculateLimits(variants, availabilityTotal);
    if (limits.max > limits.availabilityTotal) {
      return Math.round((limits.max - limits.availabilityTotal) / limits.max * 100);
    }
    return 0;
  }, [productVariants]);

  const calculateProgressInfo = useCallback((variants, availabilityTotal) => {
    const limits = calculateLimits(variants, availabilityTotal);
    if (limits.max >= limits.availabilityTotal) {
      return `${limits.max - limits.availabilityTotal} / ${limits.max}`;
    }
    return '0 / 0';
  }, [productVariants]);

  const isImageValid = (file) => {
    return file?.type === 'image/jpeg' || file?.type === 'image/png';
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = isImageValid(file);
    if (!isJpgOrPng) {
      message.error(text('onImageWrongFormat'));
    }
    const isLt6M = file.size / 1024 / 1024 < 6;
    if (!isLt6M) {
      message.error(text('onImageTooBig'));
    }
    return isJpgOrPng && isLt6M;
  };

  const showDeletionModal = () => {
    setIsDeletionModalVisible(true);
  };

  const handleConfirmEventDeletion = async () => {
    setIsDeletionUnderway(true);
    try {
      await EventsAPI.deleteProduct(event.parentId);
      navigateToEventsPage();
      message.success(text('onDeleteOk'));
    } catch (e) {
      message.error(e.response?.data?.detail);
    } finally {
      setIsDeletionUnderway(false);
      setIsDeletionModalVisible(false);
    }
  };

  const handleCancelEventDeletion = () => {
    setIsDeletionModalVisible(false);
  };

  const handleDuplicate = () => {
    setMode(MODE.DUPLICATE);
    const duplicatedVariants = productVariants.map(variant => {
      return {
        ...variant,
        id: null,
        availableSeatsQty: variant.initialAvailability
      };
    });
    setProductVariants(duplicatedVariants);
  };

  return (
    <div className='py-4'>
      <div className='flex tems-center justify-between w-full'>
        <PageTitle>{event && event.names[defaultLanguage.language]}</PageTitle>
        {(mode === 'edit' && canCreateEvents) && (
          <Button
            type='link'
            htmlType='button'
            className='uppercase'
            onClick={handleDuplicate}
          >
            {text('duplicateEvent')}
          </Button>
        )}
      </div>

      <BackButton
        iconChevron
        className='mt-4 mb-8'
        onClick={navigateToEventsPage}
        title={text('backButton')}
      />

      <Form
        name='basic'
        initialValues={formValues}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        autoComplete='off'
        layout='vertical'
        form={form}
        className='max-w-6xl'
      >
        {event && productVariants && mode === 'edit' &&
          <div className='flex items-center space-x-4 max-w-sm mb-6'>
            <Progress
              showInfo={false}
              percent={ticketPercentage}
            />
            <p className='w-40 mb-0'>
              {ticketFraction}
            </p>
          </div>}

        <Row gutter={[16, 16]} className='pt-8 pb-8'>
          <Col xs={7}>
            <LanguageSelect
              onSelect={handleLanguageSelection}
            />
          </Col>
        </Row>

        <div className='max-w-5xl'>
          <Row gutter={20}>
            <Col xs={8}>
              {isWareHouseSelectVisible &&
                <WarehouseInput />}
              <NameInput selectedLanguage={selectedLanguage} />
              <>
                <DescriptionInput selectedLanguage={selectedLanguage} />
                {currentDescription &&
                  <TrimmedDescription description={currentDescription} />}
              </>
            </Col>

            <Col xs={6}>
              <Form.Item
                name='status'
                label={text('status')}
              >
                <Select
                  getPopupContainer={trigger => trigger.parentNode}
                  style={{ width: '100%' }}
                >
                  {statusOptions.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name='date'
                label={text('date')}
                rules={[{ required: true, message: text('dateRequired') }]}
              >
                {/* HERE */}
                <DatePicker getPopupContainer={trigger => trigger.parentElement} placeholder={text('datePlaceholder')} className='w-full' disabledDate={filterStartDates} />
              </Form.Item>
              <Form.Item
                name='startTime'
                label={text('startTimeLabel')}
                rules={[{ required: true, message: text('startTimeRequired') }]}
              >
                {event &&
                  <TimePicker
                    getPopupContainer={trigger => trigger.parentElement}
                    showNow={false}
                    placeholder={text('startTime')}
                    className='w-full'
                    format='HH:mm'
                    disabledMinutes={() => disabledStartMinutes}
                    onChange={(newStartTime) => clearStartDateAndSetStartTime(newStartTime)}
                  />}
              </Form.Item>
              {isSingleVariant &&
                <Form.Item
                  name='price'
                  label={text('price')}
                  rules={[{ required: true, message: text('priceRequired') }]}
                >
                  <InputNumber
                    addonBefore='€'
                    parser={(value) => value.replace(/€\s?|(,*)/g, '')}
                    disabled={!isSingleVariant}
                    minValue={0}
                  />
                </Form.Item>}
              {isSingleVariant &&
                <Form.Item
                  name='ticketsAvailable'
                  label={text('seatingCapacity')}
                  rules={[{ required: isSingleVariant, message: text('seatingCapacityRequired') }]}
                >
                  <InputNumber
                    className='w-full'
                    placeholder={text('seatingCapacity')}
                    disabled={!isSingleVariant}
                    min={(event?.initialAvailability - event?.availableSeatsQty)}
                  />
                </Form.Item>}
            </Col>

            <Col xs={10}>
              <Form.Item
                name='category'
                label={text('category')}
                rules={[{ required: true, message: text('categoryRequired') }]}
              >
                <Select getPopupContainer={trigger => trigger.parentNode} style={{ width: '100%' }} placeholder={text('category')}>
                  {availableEventMicroCategories.map((item) => (
                    <Option key={item.value} value={item.value}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name='place'
                label={text('place')}
                rules={[{ required: true, message: text('placeRequired') }]}
              >
                <Select
                  showSearch
                  className='fixed-32-h'
                  getPopupContainer={trigger => trigger.parentNode}
                  optionFilterProp='children'
                  filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                  onSelect={(e) => handleSelect(e)}
                >
                  {locationList.map((location, i) => {
                    return (
                      <Option key={`${location.name}`} value={location.name}>
                        {location.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                name='duration'
                label={text('duration')}
                rules={[{ required: true, message: text('durationRequired') }]}
              >
                <DurationPicker
                  handleChange={handleDurationChange}
                  initialData={event}
                  startTime={startTime}
                  overMidnight={overMidnight}
                />
              </Form.Item>
              <Form.Item
                name='endDate'
                label={text('endDate')}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <Divider />
        <SectionTitle title={text('areasSection.title')} className='pb-4' />
        <HelperText text={text('areasSection.helperText')} className='mb-2' />
        <Radio.Group onChange={handleRadioChange} value={isSingleVariant} disabled>
          <Space direction='vertical'>
            <Radio
              value
              disabled={!isSingleVariant && productVariants?.length > 1}
            >
              {text('areasSection.radioOne')}
            </Radio>
            <Radio
              value={false}
            >
              {text('areasSection.radioMany')}
            </Radio>
          </Space>
        </Radio.Group>
        {!isSingleVariant &&
          <ProductVariantsEvent
            language={selectedLanguage}
            calculateProgressPercentage={calculateProgressPercentage}
            calculateProgressInfo={calculateProgressInfo}
            variants={sizeAttributes}
            productId={event.parentId}
            onDeleteVariant={() => { }}
            onEditVariant={() => { }}
            productVariants={productVariants}
            setProductVariants={setProductVariants}
            setNewVariantAdded={setNewVariantAdded}
            mode={mode}
          />}
        <Divider />
        <SectionTitle
          className='pb-4'
          title={text('additionalPropertiesSection.title')}
        />
        <Form.Item name='additionalProperties'>
          <AdditionalPropertiesList
            languages={languages}
            selectedLanguage={selectedLanguage}
          />
        </Form.Item>
        {/* Attributes */}
        <Divider />
        <SectionTitle
          className='pb-4'
          title={text('attributesSection.title')}
        />
        <Form.Item name='attributeList'>
          <ProductAttributeList
            disabledDate={filterStartDates}
            language={selectedLanguage}
            currentDepartment='event'
          />
        </Form.Item>
        {/* Gallery */}
        <Divider />
        <SectionTitle
          className='pb-4'
          title={text('gallerySection.title')}
        />
        {/* ImageGallery */}
        <ImageGalleryEvents
          fileList={productGalleryFiles.filter(img => (img?.type === COVER))}
          detailFileList={productGalleryFiles.filter(img => (img?.type !== COVER))}
          onGalleryUpdate={(file, fileList, type) => handleGalleryUpdate(file, fileList, type)}
          beforeUpload={beforeUpload}
          customRequest={customRequest}
        />
        <Divider />
        <Row gutter={[23, 23]} className='pt-8'>
          <Col span={16}>
            {/* Delete Product */}
            <Button
              type='danger'
              htmlType='button'
              className='uppercase mr-2'
              onClick={showDeletionModal}
            >
              {text('deleteEvent')}
            </Button>
          </Col>
          <Col span={4}>
            {/* Cancel Edit Product */}
            <Button
              type='secondary'
              htmlType='button'
              className='uppercase mr-2 ml-auto'
              onClick={navigateToEventsPage}
            >
              {text('cancel')}
            </Button>
          </Col>
          <Col span={4}>
            {/* Update Product */}
            <Button
              type='primary'
              htmlType='submit'
              className='uppercase ml-2'
              onClick={() => checkDurationField()}
              disabled={isLoading}
              loading={isLoading}
            >
              {mode === 'edit' ? (text('saveChanges')) : (text('createEvent'))}
            </Button>
          </Col>
        </Row>
      </Form>
      <Modal
        visible={isDeletionModalVisible}
        onOk={handleConfirmEventDeletion}
        onCancel={handleCancelEventDeletion}
        confirmLoading={isDeletionUnderway}
      >
        <div>{text('confirmDeletion')}</div>
      </Modal>
    </div>
  );
};

const NameInput = ({ selectedLanguage }) => {
  const { t } = useTranslation();
  const text = textFrom('pages.event/edit', t);

  return (
    <Form.Item
      required={false}
      name={`names${SEPARATOR}${selectedLanguage}`}
      label={<span>{text('name')}<span className='text-red-500'> *</span></span>}
    >
      <Input.TextArea
        rows={1}
        showCount
        maxLength={100}
        placeholder={text('namePlaceholder')}
      />
    </Form.Item>
  );
};

const DescriptionInput = ({ selectedLanguage }) => {
  const { t } = useTranslation();
  const text = textFrom('pages.event/edit', t);

  return (
    <Form.Item
      required={false}
      name={`descriptions${SEPARATOR}${selectedLanguage}`}
      label={<span>{text('description')}<span className='text-red-500'> *</span></span>}
    >
      <Input.TextArea
        rows='6'
        showCount
        maxLength={1500}
        placeholder={text('descriptionPlaceholder')}
      />
    </Form.Item>
  );
};

const initializeMultiLangForm = (form, event) => {
  if (event) {
    form.setFieldsValue({
      ...flatten(event, ['names', 'descriptions'])
    });
  }
};
