import React, { useEffect, useMemo } from 'react';
import { Row, Col, Button, message, Select, Form, Input } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { useUsers } from 'src/modules/admin/provider/users.provider';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { Routes } from 'src/router/Routes.helper';
import { BackButton } from 'src/components/BackButton';
import { useForm } from 'antd/lib/form/Form';
import { useLocalBusiness } from '../provider/localBusiness.provider';
import { AccountsAPI } from '../../accounts/api/AccountsApi';
import { PageTitle } from 'src/components/PageTitle';
import { getPermissionSetsFromDepartment, optionMatchesInput } from 'src/utils/utils';
import { toRoleOption, isRolePermitted } from '../utils/utils';
import { SelectLocalBusinessWithLoadMore } from 'src/components/SelectLocalBusinessWithLoadMore';

export const PageCreateUser = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.users/create', t);

  const history = useHistory();
  const { userInfo, canManageUsers } = useAuth();
  const { roles } = useUsers();
  const [form] = useForm();
  const { localBusinesses } = useLocalBusiness();

  useEffect(() => {
    if (localBusinesses.length && userInfo) {
      const userLocalBusinessValue = !canManageUsers ? userInfo?.localBusiness?.id : null;
      form.setFieldsValue({
        userLocalBusiness: userLocalBusinessValue
      });
    }
  }, [canManageUsers, localBusinesses, userInfo]);

  const navigateToUsersPage = () => {
    history.push(Routes.PATHS.USERS);
  };

  const ROLE_OPTIONS = useMemo(() => (
    roles
      .filter(isRolePermitted)
      .map(toRoleOption)
  ), [roles]);

  const onFinish = async (values) => {
    const {
      userFirstName,
      userLastName,
      username,
      userEmail,
      userRole,
      userLocalBusiness
    } = values;

    const getPermissionSetsFromDepartmentBasedOnRole = (userRole) => {
      if (userRole === 'ROLE_LOCAL_BUSINESS_MANAGER') {
        return getPermissionSetsFromDepartment(localBusinesses.find(lb => lb.id === userLocalBusiness)?.departments[0]?.key);
      } else {
        return [];
      }
    };

    try {
      const newUserData = {
        type: userRole,
        username: username,
        givenName: userFirstName,
        familyName: userLastName,
        email: userEmail,
        localBusiness: {
          id: userLocalBusiness
        },
        permissionSets: getPermissionSetsFromDepartmentBasedOnRole(userRole)
      };
      await AccountsAPI.addNewUser(newUserData);
      message.success(text('createOK'));
      navigateToUsersPage();
    } catch (e) {
      message.error(text('createKO'));
    }
  };

  return (
    <div className='py-4'>
      <Form
        form={form}
        layout='vertical'
        autoComplete='off'
        initialValues={{
          userFirstName: null,
          userLastName: null,
          username: null,
          userEmail: null,
          userRole: null
        }}
        scrollToFirstError
        onFinish={onFinish}
      >
        <PageTitle>{text('createNewUser')}</PageTitle>
        <BackButton
          iconChevron
          className='mt-4 mb-8'
          onClick={navigateToUsersPage}
          title={text('backToUsers')}
        />
        <Row gutter={20} className='pt-2 mb-4'>
          <Col xs={8}>
            {/* FirstName */}
            <Form.Item
              name='userFirstName'
              label={text('firstName')}
              className='w-full px-4 pb-4'
              rules={[{ required: true, message: text('firstNameRequired') }]}
            >
              <Input placeholder={text('firstNamePlaceholder')} />
            </Form.Item>
          </Col>
          <Col xs={8}>
            {/* LastName */}
            <Form.Item
              name='userLastName'
              label={text('lastName')}
              className='w-full px-4 pb-4'
              rules={[{ required: true, message: text('lastNameRequired') }]}
            >
              <Input placeholder={text('lastNamePlaceholder')} />
            </Form.Item>
          </Col>
          <Col xs={8}>
            {/* Username */}
            <Form.Item
              name='username'
              label={text('username')}
              className='w-full px-4 pb-4'
              rules={[{ required: true, message: text('usernameRequired') }]}
            >
              <Input placeholder={text('usernamePlaceholder')} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20} className='mb-4'>
          <Col xs={8}>
            {/* Email */}
            <Form.Item
              name='userEmail'
              label={text('email')}
              className='w-full px-4 pb-4'
              rules={[
                { required: true, message: text('emailRequired') },
                { type: 'email', message: text('emailInvalid') }
              ]}
            >
              <Input placeholder={text('emailPlaceholder')} />
            </Form.Item>
          </Col>
          <Col xs={8}>
            {/* LocalBusiness */}
            <SelectLocalBusinessWithLoadMore
              formItemName='userLocalBusiness'
              label={text('localBusiness')}
              placeholder={text('localBusinessPlaceholder')}
              disabled={!canManageUsers}
              rules={[{ required: true, message: text('localBusinessRequired') }]}
            />
          </Col>
          <Col xs={8} className='mb-4'>
            {/* Role */}
            <Form.Item
              name='userRole'
              label={text('role')}
              className='w-full px-4 pb-4'
              rules={[{ required: true, message: text('roleRequired') }]}
            >
              <Select
                showSearch
                bordered
                allowClear
                className='w-full'
                label={text('role')}
                placeholder={text('rolePlaceholder')}
                options={ROLE_OPTIONS}
                filterOption={optionMatchesInput}
              />
            </Form.Item>
          </Col>
        </Row>
        {/* Actions */}
        <div className='flex items-center w-full '>
          <Button
            type='default'
            htmlType='button'
            className='uppercase mr-2 ml-auto'
            onClick={navigateToUsersPage}
          >
            {text('cancel')}
          </Button>
          <Button
            type='primary'
            htmlType='submit'
            className='uppercase ml-2'
          >
            {text('saveNewUser')}
          </Button>
        </div>
      </Form>
    </div>
  );
};
