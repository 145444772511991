import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Button, message, Select, Checkbox, Form, Input } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { useUsers } from 'src/modules/admin/provider/users.provider';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { BackButton } from 'src/components/BackButton';
import { useForm } from 'antd/lib/form/Form';
import { UsersAPI } from '../api/UsersAPI';
import { PageTitle } from 'src/components/PageTitle';
import { Routes } from 'src/router/Routes.helper';
import { toRoleOption, isRolePermitted } from '../utils/utils';
import { getPermissionSetsFromDepartment, optionMatchesInput, USER_ACTIVATION_STATUSES } from 'src/utils/utils';
import { SelectLocalBusinessWithLoadMore } from 'src/components/SelectLocalBusinessWithLoadMore';

const ROLES = {
  LB_MANAGER: 'ROLE_LOCAL_BUSINESS_MANAGER',
  SALES_ASSISTANT: 'ROLE_CLIENTELING_SALES_ASSISTANT'
};

function getPermissionSets (oldRole, newRole, department) {
  if (oldRole === newRole) {
    return undefined;
  }
  if (oldRole === ROLES.LB_MANAGER && newRole === ROLES.SALES_ASSISTANT) {
    return [];
  }
  if (oldRole === ROLES.SALES_ASSISTANT && newRole === ROLES.LB_MANAGER) {
    return getPermissionSetsFromDepartment(department);
  }
}

export const PageEditUser = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.users/edit', t);

  const history = useHistory();
  const { canManageUsers, canFullyEditUsers } = useAuth();
  const { user, roles, userEmailVerificationStatus, requestVerificationEmail } = useUsers();
  const [form] = useForm();
  const isFullEditEnabled = Object.values(ROLES).includes(user?.type) || canFullyEditUsers;
  const [userIsActive, setUserIsActive] = useState(false);
  const { EMAIL_NOT_VERIFIED, VERIFICATION_PENDING } = USER_ACTIVATION_STATUSES;

  useEffect(() => {
    if (user) {
      const {
        email,
        familyName,
        givenName,
        active,
        localBusiness,
        username,
        type: role
      } = user;

      setUserIsActive(active);
      form.setFieldsValue({
        userFirstName: givenName,
        userLastName: familyName,
        username: username,
        userEmail: email,
        userLocalBusiness: { value: localBusiness?.id, label: localBusiness?.name },
        userRole: role,
        userActivationStatus: active
      });
    }
  }, [user]);

  const setActivationStatus = () => {
    setUserIsActive(!userIsActive);
  };

  const navigateToUsersPage = () => {
    history.push(Routes.PATHS.USERS);
  };

  const ROLE_OPTIONS = useMemo(() => (
    roles
      .filter(isRolePermitted)
      .map(toRoleOption)
  ), [roles]);

  const onFinish = async (values) => {
    const {
      userFirstName,
      userLastName,
      username,
      userEmail,
      userRole,
      userLocalBusiness
    } = values;
    const {
      email: originalEmail,
      username: originalUsername,
      type: originalRole,
      localBusiness: originalLocalBusiness
    } = user;

    const permissionSets = getPermissionSets(originalRole, userRole, originalLocalBusiness?.departments?.[0]);

    try {
      const updatedUserData = {
        givenName: userFirstName,
        familyName: userLastName,
        email: isFullEditEnabled ? userEmail : originalEmail,
        username: isFullEditEnabled ? username : originalUsername,
        type: isFullEditEnabled ? userRole : originalRole,
        active: userIsActive,
        permissionSets,
        localBusiness: {
          id: isFullEditEnabled ? userLocalBusiness?.value : originalLocalBusiness?.id
        }
      };
      await UsersAPI.updateUser(user.id, updatedUserData);
      message.success(text('updateOK'));
      navigateToUsersPage();
    } catch (e) {
      message.error(text('updateKO'));
    }
  };

  return (
    <div className='py-4'>
      <Form
        form={form}
        layout='vertical'
        autoComplete='off'
        initialValues={{
          userFirstName: null,
          userLastName: null,
          username: null,
          userEmail: null,
          userRole: null,
          userActivationStatus: null
        }}
        scrollToFirstError
        onFinish={onFinish}
      >
        <PageTitle>{text('editUser')}</PageTitle>
        <BackButton
          iconChevron
          className='mt-4 mb-8'
          onClick={navigateToUsersPage}
          title={text('backToUsers')}
        />
        <Row gutter={20} className='pt-2 mb-4'>
          <Col xs={8}>
            {/* FirstName */}
            <Form.Item
              name='userFirstName'
              label={text('firstName')}
              className='w-full px-4 pb-4'
              rules={[{ required: true, message: text('firstNameRequired') }]}
            >
              <Input placeholder={text('firstNamePlaceholder')} />
            </Form.Item>
          </Col>
          <Col xs={8}>
            {/* LastName */}
            <Form.Item
              name='userLastName'
              label={text('lastName')}
              className='w-full px-4 pb-4'
              rules={[{ required: true, message: text('lastNameRequired') }]}
            >
              <Input placeholder={text('lastNamePlaceholder')} />
            </Form.Item>
          </Col>
          {isFullEditEnabled &&
            <Col xs={8}>
              {/* Username */}
              <Form.Item
                name='username'
                label={text('username')}
                className='w-full px-4 pb-4'
                rules={[{ required: true, message: text('usernameRequired') }]}
              >
                <Input placeholder={text('usernamePlaceholder')} />
              </Form.Item>
            </Col>}
        </Row>
        {isFullEditEnabled &&
          <Row gutter={20} className='mb-4'>
            <Col xs={8}>
              {/* Email */}
              <Form.Item
                name='userEmail'
                label={text('email')}
                className='w-full px-4 pb-4'
                rules={[
                  { required: true, message: text('emailRequired') },
                  { type: 'email', message: text('emailInvalid') }
                ]}
              >
                <Input placeholder={text('emailPlaceholder')} />
              </Form.Item>
            </Col>
            <Col xs={8}>
              {/* LocalBusiness */}
              <SelectLocalBusinessWithLoadMore
                formItemName='userLocalBusiness'
                label={text('localBusiness')}
                placeholder={text('localBusinessPlaceholder')}
                labelInValue
                defaultValue={{ value: user?.localBusiness?.id, label: user?.localBusiness?.name }}
                disabled={!canManageUsers}
                rules={[{ required: true, message: text('localBusinessRequired') }]}
              />
            </Col>
            <Col xs={8} className='mb-4'>
              {/* Role */}
              <Form.Item
                name='userRole'
                label={text('role')}
                className='w-full px-4 pb-4'
                rules={[{ required: true, message: text('roleRequired') }]}
              >
                <Select
                  showSearch
                  bordered
                  allowClear
                  className='w-full'
                  label={text('role')}
                  placeholder={text('rolePlaceholder')}
                  options={ROLE_OPTIONS}
                  filterOption={optionMatchesInput}
                />
              </Form.Item>
            </Col>
          </Row>}
        {isFullEditEnabled &&
          <Row gutter={20}>
            <Col xs={8}>
              {/* ActivationStatus */}
              <Form.Item
                label=' '
                name='activationStatus'
                required={false}
              >
                <div className='checkbox-one-line'>
                  <div>{text('active')}</div>
                  <Checkbox
                    checked={userIsActive}
                    onClick={setActivationStatus}
                  />
                </div>
              </Form.Item>
            </Col>
            {userEmailVerificationStatus &&
              (userEmailVerificationStatus === EMAIL_NOT_VERIFIED || userEmailVerificationStatus === VERIFICATION_PENDING) &&
                <Col xs={8}>
                  <div>
                    <Button
                      type='default'
                      htmlType='button'
                      className='uppercase request-verification-btn'
                      onClick={() => requestVerificationEmail(user)}
                    >
                      {text('requestVerification')}
                    </Button>
                  </div>
                </Col>}
          </Row>}
        {/* Actions */}
        <div className='flex items-center w-full mt-4'>
          <Button
            type='default'
            htmlType='button'
            className='uppercase mr-2 ml-auto'
            onClick={navigateToUsersPage}
          >
            {text('cancel')}
          </Button>
          <Button
            type='primary'
            htmlType='submit'
            className='uppercase ml-2'
          >
            {text('saveChanges')}
          </Button>
        </div>
      </Form>
    </div>
  );
};
