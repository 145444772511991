import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Row,
  Col,
  Input,
  Select,
  Form,
  DatePicker,
  TimePicker,
  InputNumber,
  message,
  Button,
  Divider,
  Radio,
  Space
} from 'antd';
import { BackButton } from 'src/components/BackButton';
import { useForm, useWatch } from 'antd/lib/form/Form';
import moment from 'moment';
import { EventsAPI } from '../api/EventsAPI';
import { v4 as uuidv4 } from 'uuid';
import { useEventDetails } from '../provider/eventDetails.provider';
import { DurationPicker } from '../components/DurationPicker';
import { SectionTitle } from 'src/components/forms/SectionTitle';
import { HelperText } from 'src/components/forms/HelperText';
import { ProductVariantsEvent } from 'src/modules/products/components/ProductVariantsEvent';
import { AdditionalPropertiesList } from '../components/AdditionalPropertiesList';
import { ProductAttributeList } from 'src/modules/products/components/ProductAttributeList';
import { ImageGalleryEvents } from '../components/ImageGalleryEvents';
import { getCorrectMinutesAndHours, isBefore } from '../../../utils/utils';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { LocationAPI } from '../../../modules/locations/api/LocationAPI';
import { useLanguages } from 'src/modules/global/provider/languages.provider';
import { LanguageSelect } from 'src/components/inputs/LanguageSelect';
import { nestify } from 'src/utils/objectManipulation';
import { PageTitle } from 'src/components/PageTitle';
import { SEPARATOR } from 'src/utils/constants';
import { Routes } from 'src/router/Routes.helper';
import { TrimmedDescription } from '../components/TrimmedDescription';
import { ConfirmationModal } from 'src/components/modals/ConfirmationModal';
import { SCOPE } from 'src/utils/scope-utils';

const { Option } = Select;
const { PLATFORM, TENANT } = SCOPE;
const INITIAL_STATUS = 'Draft';
const COVER = 'cover';

export const PageCreateEvent = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.event/create', t);

  const [isSingleVariant, setIsSingleVariant] = useState(true);
  const [isCreatingEvent, setIsCreatingEvent] = useState(false);
  const [productGalleryFiles, setProductGalleryFiles] = useState([]);
  const [overMidnight, setOverMidnight] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const { selectedUserScope, canCreateEvents } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const { languages, defaultLanguage } = useLanguages();
  const [selectedLanguage, setSelectedLanguage] = useState();
  const MODAL_TITLE = text('modal.title');
  const MODAL_BODY = text('modal.body');
  const WAREHOUSE_TYPE_MANUAL = 'MANUAL';
  const [isWareHouseSelectVisible, setIsWareHouseSelectVisible] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [warehousesOptions, setWarehousesOptions] = useState([]);

  const {
    availableEventMicroCategories,
    sizeAttributes,
    productVariants,
    setProductVariants,
    warehouses
  } = useEventDetails();

  const [form] = useForm();
  const history = useHistory();

  const currentDescription = useWatch(`descriptions${SEPARATOR}${selectedLanguage}`, form);

  useEffect(() => {
    setSelectedLanguage(defaultLanguage?.language);
  }, [defaultLanguage]);

  const handleLanguageSelection = (languageId) => {
    setSelectedLanguage(languageId);
  };

  const requiredLanguage = useMemo(() => {
    return languages.find(language => language.defaultLanguage);
  }, [languages]);

  useEffect(() => {
    if (selectedUserScope === PLATFORM || selectedUserScope === TENANT) {
      setIsWareHouseSelectVisible(true);
    } else {
      setIsWareHouseSelectVisible(false);
    }
  }, [selectedUserScope, setIsWareHouseSelectVisible]);

  useEffect(() => {
    if (defaultLanguage) {
      form.setFieldsValue({ language: defaultLanguage.label });
    }
  }, [form, defaultLanguage]);

  useEffect(async () => {
    const countries = await LocationAPI.getLocations({ limit: 999, pageNumber: 1 });
    if (countries.data && countries.data.length > 0) {
      setLocationList(countries.data);
    }
    form.setFieldsValue({ status: INITIAL_STATUS });
  }, [form]);

  useEffect(() => {
    const initializeWarehousesOptions = (warehouses) => {
      setWarehousesOptions(
        warehouses
          .filter(warehouse => warehouse?.type === WAREHOUSE_TYPE_MANUAL)
          .map((warehouse) =>
            <Option key={warehouse.warehouseIdentifier} value={warehouse.warehouseIdentifier}>
              {warehouse?.name ?? warehouse.warehouseIdentifier}
            </Option>
          )
      );
    };

    initializeWarehousesOptions(warehouses);
  }, [warehouses]);

  const handleSelect = (value) => {
    const selectedLocation = locationList.find(location => location.id === value);
    if (selectedLocation) {
      setSelectedLocation(selectedLocation);
      form.setFieldsValue({ place: selectedLocation.name });
    }
  };

  /**
  * @description Shows modal
  */
  const showModal = () => {
    setIsModalVisible(true);
  };

  /**
  * @description Handles modal's confirmation
  */
  const handleModalOk = () => {
    setIsModalVisible(false);
    submitForm();
  };

  /**
  * @description Handles modal's dismission
  */
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = () => {
    const values = form.getFieldsValue(true); // Get all the values, even the unmounted ones
    const { names, descriptions } = nestify(values, ['names', 'descriptions']);

    if (!names?.[requiredLanguage.language]) {
      message.error(text('onMissingLocalizedValue', { language: requiredLanguage.language, value: text('name') }));
      return;
    }
    if (!descriptions?.[requiredLanguage.language]) {
      message.error(text('onMissingLocalizedValue', { language: requiredLanguage.language, value: text('description') }));
      return;
    }

    if (selectedUserScope === PLATFORM || selectedUserScope === TENANT) {
      showModal();
    } else {
      submitForm();
    }
  };

  const submitForm = async () => {
    const values = form.getFieldsValue(true); // Get all the values, even the unmounted ones
    const { names, descriptions } = nestify(values, ['names', 'descriptions']);

    const {
      warehouseIdentifier,
      category,
      date,
      startTime,
      duration,
      price,
      ticketsAvailable,
      status,
      additionalProperties,
      attributeList
    } = values;

    if (duration === 0) {
      form.setFields([
        {
          name: 'duration',
          errors: [text('onDurationNotEnough')]
        }
      ]);
      return;
    }

    if (!isSingleVariant && productVariants.length === 0) {
      message.error(text('onAreaMissing'));
      return;
    }

    const eventData = {
      names: names,
      descriptions: descriptions,
      microCategory: category,
      macroCategory: availableEventMicroCategories.find(item => item.value === category).macroCategory,
      availableSeatsQty: ticketsAvailable,
      price,
      currency: 'EUR',
      eventId: uuidv4(),
      published: status === 'Published',
      manufacturerCode: null,
      attributes: {
        start_date: moment(date).format('YYYY-MM-DD'),
        start_time: moment(startTime).format('HH:mm'),
        durationInMin: duration,
        location: 'no-location',
        ...(attributeList ?? []).reduce((accumulator, value) => {
          return { ...accumulator, [value.code]: value.key };
        }, {})
      },
      images: productGalleryFiles?.map(image => image.imageURL),
      tags: {},
      eventPlace: {
        name: selectedLocation.name,
        key: selectedLocation.key,
        description: ''
      },
      warehouseId: warehouseIdentifier || warehouses.find((warehouse) => warehouse.type === 'MANUAL').warehouseIdentifier,
      initialAvailability: ticketsAvailable,
      additionalProperties
    };

    if (attributeList && attributeList.length > 0) {
      const seriesStartDate = attributeList.find((itm) => itm.code === 'series_start_date');
      if (seriesStartDate) {
        eventData.attributes.series_start_date = seriesStartDate.value ? moment(seriesStartDate.value).format('YYYY-MM-DD') : seriesStartDate.key || 'no-date';
      }
      const seriesEndDate = attributeList.find((itm) => itm.code === 'series_end_date');
      if (seriesEndDate) {
        eventData.attributes.series_end_date = seriesEndDate.value ? moment(seriesEndDate.value).format('YYYY-MM-DD') : seriesEndDate.key || 'no-date';
      }
      const seriesID = attributeList.find((itm) => itm.code === 'series_id');
      if (seriesID) {
        eventData.attributes.series_id = seriesID.value || seriesID.key || 'no-series';
      }

      // IF START & END EXISTS
      if (eventData.attributes.series_start_date !== 'no-date' && eventData.attributes.series_end_date !== 'no-date') {
        form.setFields([
          {
            name: 'attributeList',
            errors: eventData.attributes.series_end_date < eventData.attributes.series_start_date ? ['series_start_date cant be greater than series_end_date!'] : []
          }
        ]);
        if (eventData.attributes.series_end_date < eventData.attributes.series_start_date) {
          return;
        }
      }
    }

    const upsertEventWithVariantsDetail = async (eventData, variant, manufacturerCode = null) => {
      const newEventData = { ...eventData };
      newEventData.availableSeatsQty = variant.availableSeatsQty || variant.initialAvailability;
      newEventData.price = variant.price;
      newEventData.attributes.location = variant.attributes.location;
      newEventData.manufacturerCode = manufacturerCode;
      newEventData.initialAvailability = variant.initialAvailability || variant.availableSeatsQty;
      await EventsAPI.createEvent(newEventData);
    };

    try {
      setIsCreatingEvent(true);
      // check if multiple area, if so create multiple events
      if (!isSingleVariant && productVariants.length > 0) {
        for (let index = 0; index < productVariants.length; index++) {
          await upsertEventWithVariantsDetail(eventData, productVariants[index]);
        }
      } else {
        // create single event variant
        await EventsAPI.createEvent(eventData);
      }
      setIsCreatingEvent(false);
      message.success(text('onEventCreatedOk'));
      navigateToUpcomingEventsPage();
    } catch (error) {
      setIsCreatingEvent(false);
      message.error(text('onError'));
    }
  };

  const navigateToUpcomingEventsPage = () => {
    history.push(Routes.PATHS.PRODUCTS_EVENT_UPCOMING);
  };

  const checkDurationField = () => {
    const values = form.getFieldsValue();
    if (values.duration === 0) {
      setTimeout(() => {
        form.setFields([
          {
            name: 'duration',
            errors: [text('onDurationNotEnough')]
          }
        ]);
      }, 200);
    }
  };

  const convertToMinutes = (days, hours, minutes) => (days * 1440 + hours * 60 + minutes);

  const handleDurationChange = duration => {
    const { days, hours, minutes } = duration;
    form.setFieldsValue({ duration: convertToMinutes(days || 0, hours || 0, minutes || 0) });
    // calculate the end date
    const { startTime, date } = form.getFieldsValue();
    const min = startTime?.minute() || 0;
    const hour = startTime?.hour() || 0;
    const endDay = moment(date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).add(days, 'days').add(hours, 'hours').add(minutes, 'minutes');
    const endDate = moment(endDay).add(hour, 'hours').add(min, 'minutes');
    const formattedDay = moment(endDate).format('DD-MM-YYYY');
    const formattedHours = moment(endDate).format('HH:mm');
    const valueDate = `${formattedDay} ${formattedHours}`;
    const thresholdDate = moment(endDay).add(1, 'days').format('DD-MM-YYYY');
    const thresholdEndDate = endDate.format('DD-MM-YYYY');
    if (thresholdEndDate === thresholdDate) {
      setOverMidnight(true);
    } else {
      setOverMidnight(false);
    }
    if (!startTime || !date) {
      form.setFieldsValue({ endDate: '' });
    } else {
      form.setFieldsValue({ endDate: valueDate });
    }
    return startTime ? getCorrectMinutesAndHours(startTime._d) : null;
  };

  const disabledStartMinutes = [];
  const gap = 15;
  for (let index = 0; index < 60; index++) {
    if (index % gap !== 0) {
      disabledStartMinutes.push(index);
    }
  }

  const handleRadioChange = (event) => {
    setIsSingleVariant(event.target.value);
  };

  /**
   * @description Handle Gallery update
   * @param file
   * @param fileList
   */
  const handleGalleryUpdate = (file, fileList, type) => {
    const prevCoverGalleryFile = productGalleryFiles.filter(item => item.type === COVER);
    const prevDetailsGalleryFiles = productGalleryFiles.filter(item => item.type !== COVER);
    // if it's a deletion of a detail, allow it
    // set the cover as the first item of the array
    if (fileList.length < prevDetailsGalleryFiles.length && type !== COVER) {
      setProductGalleryFiles([...prevCoverGalleryFile, ...fileList]);
    }
  };

  const customRequest = async (info, type) => {
    const result = await EventsAPI.uploadImage(info.file);
    let index = -1;
    if (type === COVER) {
      index = productGalleryFiles.findIndex((item) => item.type === COVER);
    } else {
      index = productGalleryFiles.findIndex((item) => item.uid === info.file.uid);
    }
    const newImage = {
      active: result.data.images[0].active,
      filename: result.data.images[0].filename,
      id: index,
      imageURL: result.data.images[0].imageURL,
      url: result.data.images[0].imageURL,
      thumbUrl: result.data.images[0].imageURL,
      sizeType: result.data.images[0].sizeType,
      tag: result.data.images[0].tag,
      type: type,
      status: 'done'
    };
    const newProductGalleryFiles = [...productGalleryFiles];
    if (index > -1) {
      newProductGalleryFiles[index] = newImage;
    } else {
      newProductGalleryFiles.push(newImage);
    }
    setProductGalleryFiles(newProductGalleryFiles);
  };

  const isImageValid = (file) => {
    return file?.type === 'image/jpeg' || file?.type === 'image/png';
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = isImageValid(file);
    if (!isJpgOrPng) {
      message.error(text('onImageWrongFormat'));
    }
    const isLt6M = file.size / 1024 / 1024 < 6;
    if (!isLt6M) {
      message.error(text('onImageTooBig'));
    }
    return isJpgOrPng && isLt6M;
  };

  const isBeforeToday = isBefore(moment('00:00', 'h:mm').utc());

  /**
   * @description This function is used in the 'validator' field of Form.Item, and as such it must return a promise.
   */
  const checkIfGalleryHasFiles = () => {
    const galleryHasFiles = (productGalleryFiles || []).length > 0;
    if (galleryHasFiles) {
      return Promise.resolve();
    }
    const error = new Error();
    return Promise.reject(error);
  };

  return (
    <div className='py-4'>
      <ConfirmationModal
        isVisible={isModalVisible}
        title={MODAL_TITLE}
        body={MODAL_BODY}
        onConfirm={handleModalOk}
        onDismiss={handleModalCancel}
      />
      <Form
        name='basic'
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete='off'
        layout='vertical'
        form={form}
        className='max-w-8xl'
      >
        <PageTitle>{text('form.title')}</PageTitle>
        <BackButton
          iconChevron
          className='mt-4 mb-8'
          onClick={navigateToUpcomingEventsPage}
          title={text('backButton')}
        />

        <div className='max-w-5xl'>

          <Row gutter={[16, 16]} className='pt-2 pb-8'>
            <Col xs={8}>
              <LanguageSelect
                onSelect={handleLanguageSelection}
              />
            </Col>
          </Row>

          <Row gutter={20} className='h-24'>
            <Col xs={8}>
              {/* Warehouse */}
              {isWareHouseSelectVisible &&
                <Form.Item
                  name='warehouseIdentifier'
                  label={text('form.warehouse.label')}
                  rules={[{ required: true, message: text('form.warehouse.message') }]}
                >
                  <Select
                    showSearch
                    bordered
                    allowClear
                    className='w-full'
                    placeholder={text('form.warehouse.placeholder')}
                    getPopupContainer={trigger => trigger.parentNode}
                    filterOption={(inputValue, option) =>
                      option.children.toLowerCase().includes(inputValue.toLowerCase())}
                  >
                    {warehousesOptions}
                  </Select>
                </Form.Item>}
            </Col>
            <Col xs={6}>
              <Form.Item
                name='status'
                label={text('form.status.label')}
                rules={[{ required: true, message: text('form.status.message') }]}
              >
                <Select
                  getPopupContainer={trigger => trigger.parentNode}
                  style={{ width: '100%' }}
                >
                  <Option value='Draft'>{text('form.status.optionDraft')}</Option>
                  <Option value='Published'>{text('form.status.optionPublished')}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={10}>
              <Form.Item
                name='category'
                label={text('form.category.label')}
                rules={[{ required: true, message: text('form.category.message') }]}
              >
                <Select getPopupContainer={trigger => trigger.parentNode} style={{ width: '100%' }} placeholder={text('form.category.placeholder')}>
                  {availableEventMicroCategories.map((item) =>
                    <Option key={item.value} value={item.value}>
                      {item.name}
                    </Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col xs={8}>
              <NameInput selectedLanguage={selectedLanguage} />
              <>
                <DescriptionInput selectedLanguage={selectedLanguage} />
                {currentDescription &&
                  <TrimmedDescription description={currentDescription} />}
              </>
            </Col>
            <Col xs={6}>
              <Form.Item
                name='date'
                label={text('form.date.label')}
                rules={[{ required: true, message: text('form.date.message') }]}
              >
                <DatePicker
                  className='w-full'
                  format='DD-MM-YYYY'
                  getPopupContainer={trigger => trigger.parentElement} placeholder={text('form.date.placeholder')}
                  disabledDate={isBeforeToday}
                />
              </Form.Item>
              <Form.Item
                name='startTime'
                label={text('form.startTime.label')}
                rules={[{ required: true, message: text('form.startTime.message') }]}
              >
                <TimePicker
                  getPopupContainer={trigger => trigger.parentElement}
                  showNow={false}
                  placeholder={text('form.startTime.placeholder')}
                  className='w-full'
                  format='HH:mm'
                  onChange={(newStartTime) => setStartTime(newStartTime)}
                  disabledMinutes={() => disabledStartMinutes}
                />
              </Form.Item>
              <Form.Item
                name='price'
                label={text('form.price.label')}
                rules={[{ required: isSingleVariant, message: text('form.price.message') }]}
              >
                <InputNumber
                  addonBefore='€'
                  parser={(value) => value.replace(/€\s?|(,*)/g, '')}
                  disabled={!isSingleVariant}
                  minValue={0}
                />
              </Form.Item>
              <Form.Item
                name='ticketsAvailable'
                label={text('form.seatingCapacity.label')}
                rules={[{ required: isSingleVariant, message: text('form.seatingCapacity.message') }]}
              >
                <InputNumber placeholder={text('form.seatingCapacity.placeholder')} className='w-full' disabled={!isSingleVariant} min={0} />
              </Form.Item>
            </Col>

            <Col xs={10}>
              <Form.Item
                name='place'
                label={text('form.place.label')}
                rules={[{ required: true, message: text('form.place.message') }]}
              >
                <Select
                  showSearch
                  className='fixed-32-h'
                  getPopupContainer={trigger => trigger.parentNode}
                  optionFilterProp='children'
                  filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                  onSelect={(e) => handleSelect(e)}
                >
                  {locationList.map((location, i) =>
                    <Option key={`${location.id}`} value={location.id}>
                      {location.name}
                    </Option>
                  )}
                </Select>
              </Form.Item>
              <Form.Item
                name='duration'
                label={text('form.duration.label')}
                rules={[{ required: true, message: text('form.duration.message') }]}
              >
                <DurationPicker
                  overMidnight={overMidnight}
                  handleChange={handleDurationChange}
                  startTime={startTime}
                />
              </Form.Item>
              <Form.Item
                name='endDate'
                label={text('form.endTime.label')}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <Divider />
        <SectionTitle title={text('areasSection.title')} className='pb-4' />
        <HelperText text={text('areasSection.helperText')} className='mb-2' />
        <Radio.Group onChange={handleRadioChange} value={isSingleVariant}>
          <Space direction='vertical'>
            <Radio value>{text('areasSection.radioOne')}</Radio>
            <Radio value={false}>{text('areasSection.radioMany')}</Radio>
          </Space>
        </Radio.Group>
        {!isSingleVariant &&
          <ProductVariantsEvent
            language={selectedLanguage}
            variants={sizeAttributes}
            productId='product.id'
            onDeleteVariant={() => { }}
            onEditVariant={() => { }}
            productVariants={productVariants}
            setProductVariants={setProductVariants}
          />}
        <Divider />
        <SectionTitle className='pb-4' title={text('additionalPropertiesSection.title')} />
        <Form.Item name='additionalProperties'>
          <AdditionalPropertiesList
            languages={languages}
            selectedLanguage={selectedLanguage}
          />
        </Form.Item>
        {/* Attributes */}
        <Divider />
        <SectionTitle className='pb-4' title={text('attributesSection.title')} />
        <Form.Item name='attributeList'>
          <ProductAttributeList
            disabledDate={isBeforeToday}
            language={selectedLanguage}
            currentDepartment='event'
          />
        </Form.Item>
        {/* Gallery */}
        <Divider />
        <SectionTitle title={text('gallerySection.title')} className='pb-4' />
        {/* ImageGallery */}
        <Form.Item
          name='gallery'
          rules={[
            {
              validator: checkIfGalleryHasFiles,
              message: text('gallerySection.onGalleryEmpty')
            }
          ]}
        >
          <ImageGalleryEvents
            fileList={productGalleryFiles.filter(img => (img?.type === COVER))}
            detailFileList={productGalleryFiles.filter(img => (img?.type !== COVER))}
            onGalleryUpdate={(file, fileList, type) => handleGalleryUpdate(file, fileList, type)}
            beforeUpload={beforeUpload}
            customRequest={customRequest}
          />
        </Form.Item>
        <div className='flex items-center w-full '>
          <Button
            type='secondary'
            htmlType='button'
            className='uppercase mr-2 ml-auto  mt-40'
          >
            {text('cancelButton')}
          </Button>
          <Button
            type='primary'
            htmlType='submit'
            className='uppercase ml-2 mt-40'
            onClick={() => checkDurationField()}
            loading={isCreatingEvent}
            disabled={isCreatingEvent || !canCreateEvents}
          >
            {text('createEventButton')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

const NameInput = ({ selectedLanguage }) => {
  const { t } = useTranslation();
  const text = textFrom('pages.event/create', t);

  return (
    <Form.Item
      required={false}
      name={`names${SEPARATOR}${selectedLanguage}`}
      label={<span>{text('form.name.label')}<span className='text-red-500'> *</span></span>}
    >
      <Input.TextArea
        showCount
        maxLength={100}
        placeholder={text('form.name.placeholder')}
      />
    </Form.Item>
  );
};

const DescriptionInput = ({ selectedLanguage }) => {
  const { t } = useTranslation();
  const text = textFrom('pages.event/create', t);

  return (
    <Form.Item
      required={false}
      name={`descriptions${SEPARATOR}${selectedLanguage}`}
      label={<span>{text('form.description.label')}<span className='text-red-500'> *</span></span>}
    >
      <Input.TextArea
        rows='6'
        showCount
        maxLength={1500}
        placeholder={text('form.description.placeholder')}
      />
    </Form.Item>
  );
};
