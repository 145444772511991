import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import moment from 'moment';

export const ANY_OPTION = {
  value: 'Any',
  label: 'Any'
};

export const imageType = {
  logo: 'logo',
  map: 'map',
  cover: 'cover'
};

export const always = a => b => a;

export function isBefore (date1) {
  return (date2) => {
    return (date2?.isBefore(date1));
  };
}

export function isAfter (date1) {
  return (date2) => {
    return (date2?.isAfter(date1));
  };
}

export const MODE = {
  EDIT: 'edit',
  DUPLICATE: 'duplicate'
};

export const days = [
  {
    id: 1,
    name: 'Monday'
  },
  {
    id: 2,
    name: 'Tuesday'
  },

  {
    id: 3,
    name: 'Wednesday'
  },
  {
    id: 4,
    name: 'Thursday'
  },
  {
    id: 5,
    name: 'Friday'
  },
  {
    id: 6,
    name: 'Saturday'
  },
  {
    id: 7,
    name: 'Sunday'
  }
];

export const countries = [
  {
    key: 'IT',
    districts: [
      { key: 'BA' },
      { key: 'BG' },
      { key: 'BI' },
      { key: 'BO' },
      { key: 'BN' },
      { key: 'BL' },
      { key: 'BS' },
      { key: 'BR' },
      { key: 'BT' },
      { key: 'BZ' },
      { key: 'RC' },
      { key: 'RA' },
      { key: 'RG' },
      { key: 'RE' },
      { key: 'RI' },
      { key: 'RO' },
      { key: 'RN' },
      { key: 'RM' },
      { key: 'AG' },
      { key: 'AN' },
      { key: 'AO' },
      { key: 'AL' },
      { key: 'IS' },
      { key: 'AV' },
      { key: 'AT' },
      { key: 'IM' },
      { key: 'AR' },
      { key: 'AP' },
      { key: 'AQ' },
      { key: 'VE' },
      { key: 'LC' },
      { key: 'LE' },
      { key: 'LI' },
      { key: 'TP' },
      { key: 'TS' },
      { key: 'TR' },
      { key: 'LO' },
      { key: 'TV' },
      { key: 'LU' },
      { key: 'LT' },
      { key: 'TO' },
      { key: 'TN' },
      { key: 'TA' },
      { key: 'TE' },
      { key: 'CL' },
      { key: 'CN' },
      { key: 'CO' },
      { key: 'CH' },
      { key: 'CI' },
      { key: 'CE' },
      { key: 'CA' },
      { key: 'CB' },
      { key: 'CZ' },
      { key: 'CT' },
      { key: 'CR' },
      { key: 'CS' },
      { key: 'SV' },
      { key: 'SP' },
      { key: 'SR' },
      { key: 'SS' },
      { key: 'SO' },
      { key: 'SI' },
      { key: 'KR' },
      { key: 'SA' },
      { key: 'VR' },
      { key: 'FR' },
      { key: 'FG' },
      { key: 'FE' },
      { key: 'FC' },
      { key: 'FM' },
      { key: 'FI' },
      { key: 'NO' },
      { key: 'NA' },
      { key: 'NU' },
      { key: 'EN' },
      { key: 'MB' },
      { key: 'MC' },
      { key: 'ME' },
      { key: 'MI' },
      { key: 'MN' },
      { key: 'MO' },
      { key: 'MS' },
      { key: 'MT' },
      { key: 'UD' },
      { key: 'PU' },
      { key: 'PT' },
      { key: 'PV' },
      { key: 'PR' },
      { key: 'PZ' },
      { key: 'PE' },
      { key: 'PD' },
      { key: 'PG' },
      { key: 'PA' },
      { key: 'PC' },
      { key: 'PO' },
      { key: 'PN' },
      { key: 'PI' },
      { key: 'GR' },
      { key: 'VC' },
      { key: 'VB' },
      { key: 'VA' },
      { key: 'VI' },
      { key: 'VV' },
      { key: 'VT' },
      { key: 'VS' },
      { key: 'GE' },
      { key: 'GO' },
      { key: 'OG' },
      { key: 'OR' },
      { key: 'OT' }
    ]
  }
];

export const CountriesUtils = () => {
  const { t } = useTranslation();
  const text = textFrom('utils', t);

  const getCountriesList = () => {
    const countriesList = countries.map(country => (
      {
        key: country.key,
        value: text(`countries.${country.key}.country`)
      }
    ));
    return countriesList || [];
  };

  const getDistrictListByCountry = (countryKey) => {
    const country = countries.find(country => (country.key === countryKey));
    const districtList = country?.districts?.map(district => (
      {
        key: district.key,
        value: text(`countries.${country.key}.districts.${district.key}`)
      }
    ));
    return districtList || [];
  };

  return { getCountriesList, getDistrictListByCountry };
};

// TODO as soon as other roles are managed by the FE, this array of keys should be removed
export const managedRoleKeys = ['ROLE_ADMIN', 'ROLE_RETAILER'];

export const getTimeLines = () => {
  const x = 30; // minutes interval
  const times = []; // time array
  let tt = 0; // start time
  const ap = ['AM', 'PM']; // AM-PM

  // loop to increment the time and push results in array
  for (let i = 0; tt < 24 * 60; i++) {
    const hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
    const mm = tt % 60; // getting minutes of the hour in 0-55 format
    times[i] =
      ('0' + (hh % 12)).slice(-2) +
      ':' +
      ('0' + mm).slice(-2) +
      ap[Math.floor(hh / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
    tt = tt + x;
  }
  // converting to 24h format
  for (let index = 0; index < times.length; index++) {
    if (times[index].includes('PM')) {
      const timePM = times[index].slice(0, 2);
      const time24Format = +timePM + 12;
      times[index] = times[index]
        .replace(/^.{2}/g, time24Format)
        .replace('PM', '');
    }
    if (times[index].includes('AM')) {
      times[index] = times[index].replace('AM', '');
    }
  }
  return times;
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const mapDayToNumber = (dayName) => {
  const foundDay = days.find((day) => day.name === dayName);
  if (foundDay) {
    return foundDay.id;
  }
};

export const mapNumberToDay = (number) => {
  const foundDay = days.find((day) => day.id === number);
  if (foundDay) {
    return foundDay.name;
  }
};

export const minutesUntilMidnight = (limit) => {
  const midnight = new Date();
  midnight.setHours(23);
  midnight.setMinutes(59);
  midnight.setSeconds(0);
  return (midnight.getTime() - limit.getTime()) / 1000 / 60;
};

export const formatTimeTableValues = (values) => {
  const data = [];
  Object.entries(values).forEach((entry) => {
    const [key, value] = entry;
    if (key.includes('lunch')) {
      const dayOfTheWeek = mapDayToNumber(
        key.replace('_lunch_from', '').replace('_lunch_until', '')
      );
      const item = data.find(
        (item) =>
          item.dayOfTheWeek === dayOfTheWeek && item.slotOfTheDay === 'LUNCH'
      );
      if (item) {
        if (key.includes('_lunch_from')) {
          item.opening = value;
        } else {
          item.closing = value;
        }
      } else {
        data.push({
          slotOfTheDay: 'LUNCH',
          dayOfTheWeek,
          opening: key.includes('_lunch_from') ? value : '',
          closing: key.includes('_lunch_until') ? value : '',
          capacity: values.maxPickupsForSlotLunch
        });
      }
    }

    if (key.includes('dinner')) {
      const dayOfTheWeek = mapDayToNumber(
        key.replace('_dinner_from', '').replace('_dinner_until', '')
      );
      const item = data.find(
        (item) =>
          item.dayOfTheWeek === dayOfTheWeek && item.slotOfTheDay === 'DINNER'
      );
      if (item) {
        if (key.includes('_dinner_from')) {
          item.opening = value;
        } else {
          item.closing = value;
        }
      } else {
        data.push({
          slotOfTheDay: 'DINNER',
          dayOfTheWeek,
          opening: key.includes('_dinner_from') ? value : '',
          closing: key.includes('_dinner_until') ? value : '',
          capacity: values.maxPickupsForSlotDinner
        });
      }
    }
  });
  return data;
};

export const MINUTES_GAP = 15;

export const calculateTimeBeforeMidnight = (startTime) => {
  const midnight = new Date();
  midnight.setHours(24);
  midnight.setMinutes(0);
  midnight.setSeconds(0);
  midnight.setMilliseconds(0);
  return Math.round((midnight.getTime() - startTime.getTime()) / 1000 / 60) - 1;
};

export const getCorrectMinutesAndHours = (startTime) => {
  let data = { hours: null, minutes: null, minutesDelta: null, selectedTime: null };
  if (startTime) {
    const minutes = calculateTimeBeforeMidnight(startTime);
    const remainingHours = Math.floor(minutes / 60);
    const remainingMinutes = minutes - (remainingHours * 60);
    const hoursArray = [0];
    for (let index = 1; index <= remainingHours; index++) {
      hoursArray.push(index);
    }
    const minutesArray = [0];
    for (let index = MINUTES_GAP; index < 60; index++) {
      if (index % MINUTES_GAP === 0) {
        minutesArray.push(index);
      }
    }
    const minutesDeltaArray = [0];
    for (let index = MINUTES_GAP; index < remainingMinutes; index++) {
      if (index % MINUTES_GAP === 0) {
        minutesDeltaArray.push(index);
      }
    }
    const selectedTime = { hours: startTime.getHours(), minutes: startTime.getMinutes() };
    data = { hours: hoursArray, minutes: minutesArray, minutesDelta: minutesDeltaArray, selectedTime };
  }
  return data;
};

export const minutesToObj = (min) => {
  const intMin = +min;
  const days = 0;
  const hours = Math.floor(intMin / 60);
  const minutes = hours ? intMin % (hours * 60) : intMin;
  return { days, hours, minutes };
};

export const convertToMinutes = (days, hours, minutes) => (days * 1440 + hours * 60 + minutes);

export const trim80Characters = (string) => {
  return trimText(80, string);
};

export const trim200Characters = (string) => {
  return trimText(200, string);
};

export const trimText = (max, string) => {
  if (string?.length > max) {
    const trimmedString = string.slice(0, max);
    return `${trimmedString}...`;
  }
  return string;
};

/**
 * @description This function receives the delivery time expressed as a string in the HH:MM:SS format and returns it in the HH:MM format
 * @signature (a: string) => string
 */
export function deliveryTimeFrom (str) {
  return /^\d\d:\d\d/.exec(str)?.[0];
}

export const checkIfArrayAIsSubsetOfArrayB = (subset, superset) => {
  if (Array.isArray(subset) && Array.isArray(superset)) {
    return subset.every(subsetItem => superset.includes(subsetItem));
  }
  return false;
};

/**
 * @description This function turns a string into an option for the <Select> element
 * @signature (a: string) => { value: string, label: string }
 */
export function toOption (str) {
  return {
    value: str,
    label: str
  };
}

export function isObject (obj) {
  return (
    typeof obj === 'object' &&
    !Array.isArray(obj) &&
    obj !== undefined &&
    obj !== null
  );
}

export function isString (str) {
  return typeof str === 'string';
}

export function isArrayOfStrings (array) {
  return (
    Array.isArray(array) &&
    array.length > 0 &&
    array.every(el => isString(el))
  );
}

export const regionNamesTranslated = (lang) => {
  return new Intl.DisplayNames([lang], { type: 'language' });
};

export const firstLetterUppercase = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export function sortByProperty (property) {
  return (a, b) => {
    if (a?.[property] > b?.[property]) return 1;
    if (a?.[property] < b?.[property]) return -1;
    return 0;
  };
}

export const getPermissionSetsFromDepartment = (department) => {
  switch (department) {
    case 'food':
      return ['FOOD_LB_MANAGER'];
    case 'event':
      return ['EVENT_LB_MANAGER'];
    case 'fashion':
      return ['RETAIL_LB_MANAGER'];
    default:
      return ['BASIC_LB_MANAGER'];
  }
};

export function optionMatchesInput (input, option) {
  return option.label.toLowerCase().includes(input.toLowerCase());
}

export const USER_ACTIVATION_STATUSES = {
  EMAIL_NOT_VERIFIED: 'UNVERIFIED',
  VERIFICATION_PENDING: 'PENDING'
};

export const checkIfCurrentPage = (page) => window.location.pathname === page;

export const hasMoreThanXCharacters = (string, x) => string?.length > x;

export function tagLabel (tag, service) {
  switch (tag.key) {
    case 'published':
      return service.getStatus(tag.label);
    case 'startDate':
    case 'endDate':
      return moment(tag.label).format('YYYY-MM-DD');
    default:
      return tag.label ?? tag.value;
  }
}

export function hasReachedScrollBottom (e) {
  return e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
}
