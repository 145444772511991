import React, { useCallback, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { Col, DatePicker, Form, Row } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { useTranslation } from 'react-i18next';
import { SectionTitle } from 'src/components/forms/SectionTitle';
import { DefaultButton } from 'src/components/buttons/DefaultButton';
import { PrimaryButton } from 'src/components/buttons/PrimaryButton';
import { always, isAfter, isBefore } from 'src/utils/utils';

const MAX_NUMBER_OF_CLOSING_PERIODS = 5;

const FORM = {
  CLOSING_PERIODS: 'closingPeriods',
  START_DATE: 'start',
  END_DATE: 'end'
};

export const ClosingPeriods = ({ form, initialClosingPeriods }) => {
  const { t } = useTranslation();
  const text = textFrom('components.closingPeriods', t);

  const currentPeriods = Form.useWatch('closingPeriods', form);
  const isLastPeriod = index => index === currentPeriods?.length - 1;
  const hasMoreThanOnePeriod = currentPeriods?.length > 1;
  const areMorePeriodsAvailable = currentPeriods?.length < MAX_NUMBER_OF_CLOSING_PERIODS;

  const initializeComponent = (closingPeriods) => {
    const formattedPeriods =
      (isEmpty(closingPeriods))
        ? [{}]
        : closingPeriods?.map(period => ({
          start: period.start ? moment(period.start) : null,
          end: period.end ? moment(period.end) : null
        }));
    form.setFieldsValue({ [FORM.CLOSING_PERIODS]: formattedPeriods });
  };

  useEffect(() => {
    if (initialClosingPeriods) {
      initializeComponent(initialClosingPeriods);
    }
  }, [initialClosingPeriods]);

  const isAfterEndDate = useCallback((index) => (
    currentPeriods[index].end ? isAfter(moment(currentPeriods[index].end)) : always(false)
  ), [currentPeriods]);

  const isBeforeStartDate = useCallback((index) => (
    currentPeriods[index].start ? isBefore(moment(currentPeriods[index].start)) : always(false)
  ), [currentPeriods]);

  const canAddPeriod = useCallback((index) => (
    isLastPeriod(index) && areMorePeriodsAvailable && currentPeriods[index].start
  ), [currentPeriods, areMorePeriodsAvailable]);

  const reset = useCallback(() => {
    form.setFieldsValue({ [FORM.CLOSING_PERIODS]: [{}] });
  }, [form]);

  return (
    <div className='border-b border-gray-300 py-8'>
      <SectionTitle title={text('title')} />
      <Form.List name={FORM.CLOSING_PERIODS}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name }, index) => (
              <ClosingPeriod
                key={key}
                parentName={name}
                isAfterEndDate={isAfterEndDate(index)}
                isBeforeStartDate={isBeforeStartDate(index)}
                addPeriod={add}
                canAddPeriod={canAddPeriod(index)}
                removePeriod={index === 0 && !hasMoreThanOnePeriod ? reset : remove}
                isRequired={hasMoreThanOnePeriod}
              />
            ))}
          </>
        )}
      </Form.List>
    </div>
  );
};

const ClosingPeriod = ({
  parentName,
  addPeriod,
  canAddPeriod,
  removePeriod,
  isAfterEndDate,
  isBeforeStartDate,
  isRequired
}) => {
  const { t } = useTranslation();
  const text = textFrom('components.closingPeriods', t);

  return (
    <div>
      <Row gutter={16} className='mt-4'>
        <Col xs={8}>{text('startDate')}</Col>
        <Col xs={8}>{text('endDate')}</Col>
        <Col xs={4}>{text('clearDates')}</Col>
        <Col xs={4}>{canAddPeriod && text('addDates')}</Col>
      </Row>
      <Row gutter={16}>
        <Col xs={8}>
          <DatePickerFormItem
            formItemName={[parentName, FORM.START_DATE]}
            disabledDate={isAfterEndDate}
            isRequired={isRequired}
          />
        </Col>
        <Col xs={8}>
          <DatePickerFormItem
            formItemName={[parentName, FORM.END_DATE]}
            disabledDate={isBeforeStartDate}
          />
        </Col>
        <Col xs={4}>
          <DefaultButton label='-' onClick={() => removePeriod(parentName)} />
        </Col>
        {canAddPeriod &&
          <Col xs={4}>
            <PrimaryButton label='+' onClick={addPeriod} />
          </Col>}
      </Row>
    </div>
  );
};

const DatePickerFormItem = ({ formItemName, disabledDate, isRequired }) => {
  const { t } = useTranslation();
  const text = textFrom('components.closingPeriods', t);

  return (
    <Form.Item
      name={formItemName}
      rules={isRequired ? [{ required: true, message: text('dateRequired') }] : []}
    >
      <DatePicker
        allowClear
        format='DD-MM-YYYY'
        disabledDate={disabledDate}
      />
    </Form.Item>
  );
};
