import React, { useState } from 'react';
import moment from 'moment';
import Moment from 'react-moment';
import { Table, message } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { DeletionModal } from 'src/components/DeletionModal';
import { useTableHeight } from 'src/hooks/useTableHeight';
import { useTranslation } from 'react-i18next';
import { ProductStatusTag } from 'src/modules/products/components/ProductStatusTag';
import { ProductsAPI } from '../api/ProductsAPI';
import { MenuAction, PopoverActionMenu } from 'src/components/PopoverActionMenu';

const CoverImage = ({ src }) => (
  <img
    src={src}
    className='h-16'
  />
);

function isPublished (variant) {
  return variant.published === 'Published';
}

export const FashionVariantTable = ({
  data,
  pagination,
  refreshProductVariants,
  onEdit
}) => {
  const { t } = useTranslation();
  const text = textFrom('components.fashionVariantTable', t);
  const tableHeight = useTableHeight();

  const [isLoading, setIsLoading] = useState(false);
  const [variantToBeDeleted, setVariantToBeDeleted] = useState(undefined);

  const prepareVariantToBeDeleted = variant => setVariantToBeDeleted(variant);

  const resetVariantToBeDeleted = () => setVariantToBeDeleted(undefined);

  const deleteVariant = async () => {
    setIsLoading(true);
    try {
      await ProductsAPI.deleteVariant(variantToBeDeleted.productId, variantToBeDeleted.id);
      resetVariantToBeDeleted();
      await refreshProductVariants();
      message.success(text('onDeleteOk'));
    } catch (err) {
      message.error(text('onDeleteErr'));
    } finally {
      setIsLoading(false);
    }
  };

  const toggleVariantStatus = async variant => {
    setIsLoading(true);
    const newStatus = !isPublished(variant);
    try {
      await ProductsAPI.updateVariantStatus(variant.productId, variant.id, newStatus);
      await refreshProductVariants();
      message.success(text('onChangeStatusOk'));
    } catch (err) {
      message.error(text('onChangeStatusErr'));
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      title: text('image'),
      dataIndex: ['photoGallery', 'images'],
      render: (images) => {
        const url = images?.find(image => image?.info?.type === 'cover')?.url;
        return (url ? <CoverImage src={url} /> : <></>);
      }
    },
    {
      title: text('EAN'),
      dataIndex: ['manufacturerCode'],
      render: (text) => <div>{text}</div>
    },
    {
      title: text('color'),
      dataIndex: ['attributeVariants', 'color', 'localeName'],
      render: (color) => <div>{color}</div>
    },
    {
      title: text('scale'),
      dataIndex: ['attributeVariants', 'sizeScale', 'businessKey'],
      render: sizeScale => sizeScale
    },
    {
      title: text('size'),
      dataIndex: ['attributeVariants', 'size', 'businessKey'],
      render: size => size
    },
    {
      title: text('price'),
      dataIndex: ['prices', 'sellingPrice'],
      render: (_, record) => (
        <div>
          {record.prices?.currency?.symbol} {record.prices?.sellingPrice}
        </div>
      )
    },
    // TODO Generalize ProductStatusTag because it is being used on product and variant table - to save time now use like this
    {
      title: text('status'),
      dataIndex: ['published'],
      render: (status) => <ProductStatusTag status={status} />
    },
    {
      title: text('lastEdit'),
      dataIndex: ['updateDate'],
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      render: (text) => <Moment format='DD MMM YYYY' date={text} />
    },
    {
      align: 'center',
      render: (_, variant) => (
        <PopoverActionMenu>
          <MenuAction onClick={onEdit?.bind(_, variant)} label={text('edit')} />
          <MenuAction onClick={toggleVariantStatus.bind(_, variant)} label={isPublished(variant) ? text('unpublish') : text('publish')} />
          <MenuAction onClick={prepareVariantToBeDeleted.bind(_, variant)} label={text('delete')} />
        </PopoverActionMenu>
      )
    }
  ];

  return (
    <>
      <Table
        sticky
        className='clickable'
        loading={isLoading}
        columns={columns}
        dataSource={data}
        pagination={pagination}
        scroll={{ y: tableHeight }}
      />

      <DeletionModal
        visible={!!variantToBeDeleted}
        onOk={deleteVariant}
        onCancel={resetVariantToBeDeleted}
      >
        <div>{text('confirmDeletion', { variant: variantToBeDeleted?.manufacturerCode })}</div>
      </DeletionModal>
    </>
  );
};
