import React, { useEffect, useState } from 'react';
import { Col, Form, Input, Row, Select, Checkbox, message } from 'antd';
import { Routes } from 'src/router/Routes.helper';
import { useForm } from 'antd/lib/form/Form';
import { toOption } from 'src/utils/utils';
import { textFrom } from 'src/utils/textFrom';
import { PageTitle } from 'src/components/PageTitle';
import { useHistory } from 'react-router-dom';
import { BackButton } from 'src/components/BackButton';
import { SubmitButton } from 'src/components/buttons/SubmitButton';
import { DangerButton } from 'src/components/buttons/DangerButton';
import { DefaultButton } from 'src/components/buttons/DefaultButton';
import { useWarehouses } from '../provider/warehouses.provider';
import { DeletionModal } from 'src/components/DeletionModal';
import { WarehousesAPI } from '../api/WarehousesAPI';
import { useTranslation } from 'react-i18next';
import { WAREHOUSE_TYPES } from '../domain/models/warehouseTypes';

const WAREHOUSE_TYPE_OPTIONS = WAREHOUSE_TYPES.map(toOption);
const PRIORITY_OPTIONS = [
  { value: 1, label: '1 High priority' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5 Medium priority' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 0, label: '0 No priority' }
];

export const PageEditWarehouse = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.warehouses/edit', t);

  const [form] = useForm();
  const history = useHistory();
  const { warehouse } = useWarehouses();
  const [isPending, setIsPending] = useState(false);
  const [isEcommerce, setIsEcommerce] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const navigateToWarehousesPage = () => {
    history.push(Routes.PATHS.WAREHOUSES);
  };

  const toggleIsEcommerce = () => {
    setIsEcommerce(!isEcommerce);
  };

  useEffect(() => {
    if (warehouse) {
      initializeForm(warehouse);
    }
  }, [warehouse]);

  const initializeForm = (warehouse) => {
    setIsEcommerce(warehouse.ecommerce);
    form.setFieldsValue({
      name: warehouse.name,
      type: warehouse.type,
      companyName: warehouse.company,
      taxId: warehouse.taxId,
      companyAddress: warehouse.address?.streetAddress,
      companyEmail: warehouse.email,
      priority: warehouse.fulfillmentPriority,
      warehouseIdentifier: warehouse.warehouseIdentifier,
      externalWarehouseIdentifier: warehouse.externalWarehouseIdentifier
    });
  };

  const onFinish = async (values) => {
    setIsPending(true);
    const data = {
      name: values.name,
      type: values.type,
      company: values.companyName,
      taxId: values.taxId,
      address: { streetAddress: values.companyAddress },
      email: values.companyEmail,
      ecommerce: isEcommerce,
      fulfillmentPriority: values.priority,
      warehouseIdentifier: values.warehouseIdentifier,
      externalWarehouseIdentifier: values.externalWarehouseIdentifier
    };
    try {
      await WarehousesAPI.updateWarehouse(warehouse.id, data);
      message.success(text('onUpdateOK'));
      navigateToWarehousesPage();
    } catch (e) {
      message.error(text('onUpdateKO'));
    } finally {
      setIsPending(false);
    }
  };

  const showDeletionModal = () => {
    setIsModalVisible(true);
  };

  const hideDeletionModal = () => {
    setIsModalVisible(false);
  };

  const confirmWarehouseDeletion = async () => {
    await WarehousesAPI.deleteWarehouse(warehouse.id);
    navigateToWarehousesPage();
  };

  return (
    <div className='py-4'>
      <PageTitle>{text('pageTitle')}</PageTitle>

      <BackButton
        iconChevron
        className='mt-4 mb-8'
        title={text('backButton')}
        onClick={navigateToWarehousesPage}
      />

      <Form
        form={form}
        layout='vertical'
        autoComplete='off'
        scrollToFirstError
        onFinish={onFinish}
      >

        <Row gutter={20} className='mb-4'>
          <Col xs={7}>
            <Form.Item
              name='name'
              label={text('name')}
              rules={[{ required: true, message: text('nameRequired') }]}
            >
              <Input placeholder={text('namePlaceholder')} />
            </Form.Item>
          </Col>

          <Col xs={5}>
            <Form.Item
              name='type'
              label={text('type')}
              rules={[{ required: true, message: text('typeRequired') }]}
            >
              <Select
                disabled
                options={WAREHOUSE_TYPE_OPTIONS}
                placeholder={text('typePlaceholder')}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20} className='mb-4'>
          <Col xs={7}>
            <Form.Item
              name='companyName'
              label={text('companyName')}
            >
              <Input placeholder={text('companyNamePlaceholder')} />
            </Form.Item>
          </Col>

          <Col xs={5}>
            <Form.Item
              name='taxId'
              label={text('taxId')}
            >
              <Input placeholder={text('taxIdPlaceholder')} />
            </Form.Item>
          </Col>

          <Col xs={7}>
            <Form.Item
              name='companyAddress'
              label={text('companyAddress')}
            >
              <Input placeholder={text('companyAddressPlaceholder')} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20} className='mb-4'>
          <Col xs={7}>
            <Form.Item
              name='companyEmail'
              label={text('companyEmail')}
            >
              <Input placeholder={text('companyEmailPlaceholder')} />
            </Form.Item>
          </Col>

          <Col xs={5}>
            <Form.Item
              name='eCommerce'
              label=' '
            >
              <div className='checkbox-one-line'>
                <div>{text('eCommerce')}</div>
                <Checkbox
                  disabled
                  checked={isEcommerce}
                  onClick={toggleIsEcommerce}
                />
              </div>
            </Form.Item>
          </Col>

          <Col xs={7}>
            <Form.Item
              name='priority'
              label={text('priority')}
            >
              <Select
                options={PRIORITY_OPTIONS}
                placeholder={text('priorityPlaceholder')}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20} className='mb-4'>
          <Col xs={7}>
            <Form.Item
              name='externalWarehouseIdentifier'
              label={text('externalWarehouseIdentifier')}
            >
              <Input placeholder={text('externalWarehouseIdentifierPlaceholder')} />
            </Form.Item>
          </Col>

          <Col xs={5}>
            <Form.Item
              name='warehouseIdentifier'
              label={text('warehouseIdentifier')}
            >
              <Input placeholder={text('warehouseIdentifierPlaceholder')} disabled />
            </Form.Item>
          </Col>
        </Row>

        <div className='flex w-full mt-12'>
          <DangerButton
            label={text('delete')}
            onClick={showDeletionModal}
          />
          <DefaultButton
            className='ml-auto mr-4'
            label={text('cancel')}
            onClick={navigateToWarehousesPage}
          />
          <SubmitButton
            label={text('saveChanges')}
            loading={isPending}
            disabled={isPending}
          />
        </div>

      </Form>

      <DeletionModal
        visible={isModalVisible}
        onOk={confirmWarehouseDeletion}
        onCancel={hideDeletionModal}
        msgOnSuccess={text('onDeleteOk')}
        msgOnError={text('onDeleteErr')}
      >
        <div>{text('confirmDeletion')}</div>
      </DeletionModal>
    </div>
  );
};
